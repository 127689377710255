import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { Spacing } from "@hark-tech/components";
import { useNavigator } from "@hark-tech/hooks";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { Button, ButtonVariant, Typography } from "../../../components";
import { CheckboxListEntry } from "../../../components/CheckboxListEntry";
import {
  AddControllableForLocationDocument,
  Controllable,
  ControllablesForUserDocument,
  RemoveControllableFromLocationDocument,
  Vendor,
} from "../../../generated/graphql/graphql";
import { getControllablesForVendor } from "../../../util";
import { RouteMap } from "../../Routes";
import { useLocation } from "../../location-provider/LocationProvider";
import { BaseHeader, ScreenWrapper } from "../ScreenWrapper";

export const UpdateLocationConnectionsForVendor = (): JSX.Element => {
  const { vendor } = useParams<{ vendor: Vendor }>();

  const { location, refetch: refetchLocation } = useLocation();
  const navigate = useNavigator();

  const [error, setError] = useState<ApolloError>();
  const [mutationLoading, setMutationLoading] = useState(false);
  const [mutationError, setMutationError] = useState<ApolloError>();
  const [controllablesToControl, setControllablesToControl] = useState<string[]>([]);
  const [vendorControllables, setVendorControllables] = useState<Controllable[]>([]);
  const [initiallyControlled, setInitiallyControlled] = useState<string[]>([]);

  const { loading, refetch } = useQuery(ControllablesForUserDocument, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      const controllables = vendor
        ? getControllablesForVendor(data.listControllablesForUser.controllables, vendor) ?? []
        : [];
      setVendorControllables(controllables);
      const initialControllables = controllables
        ?.filter((controllable) =>
          location.controllableIds.includes(controllable.id)
        )
        .map((controllable) => controllable.id);
      setInitiallyControlled(initialControllables);
      setControllablesToControl(initialControllables);
    },
    onError: (error) => {
      if (
        error.message !==
        "User must sign in with mill credentials before accessing the Mill API"
      ) {
        setError(error);
      }
    },
  });

  const [addControllableToLocation] = useMutation(
    AddControllableForLocationDocument,
    {
      onError: (error) => {
        setMutationError(error);
      },
    }
  );
  const [removeControllableFromLocation] = useMutation(
    RemoveControllableFromLocationDocument,
    {
      onError: (error) => {
        setMutationError(error);
      },
    }
  );

  const updateControllablesToControl = (id: string) => {
    if (controllablesToControl.includes(id)) {
      setControllablesToControl(
        controllablesToControl.filter((controllableId) => controllableId !== id)
      );
    } else {
      setControllablesToControl([...controllablesToControl, id]);
    }
  };

  const onSave = async () => {
    setMutationLoading(true);

    const controllablesToAdd = controllablesToControl.filter(
      (id) => !initiallyControlled.includes(id)
    );
    const controllablesToRemove = initiallyControlled.filter(
      (id) => !controllablesToControl.includes(id)
    );

    controllablesToAdd.map((controllableId) =>
      addControllableToLocation({
        variables: {
          input: {
            controllableId,
            locationId: location.id,
          },
        },
      })
    );

    controllablesToRemove.map((controllableId) =>
      removeControllableFromLocation({
        variables: {
          input: {
            controllableId,
            locationId: location.id,
          },
        },
      })
    );

    await refetch();

    setInitiallyControlled(controllablesToControl);
    setMutationLoading(false);
  };

  const goToOverview = async () => {
    await refetchLocation();
    navigate.to(RouteMap.base);
  };

  return (
    <ScreenWrapper
      header={
        <BaseHeader title={vendor + " settings"} onBackClick={goToOverview} />
      }
      queryError={error}
      queryLoading={loading}
      childSpacing={Spacing.SMALL}
    >
      <Typography text="These rooms may be subject to smart program adjustment, in order to reduce cost." />

      {vendorControllables?.map((controllable) => {
        return (
          <CheckboxListEntry
            checked={controllablesToControl.includes(controllable.id)}
            id={controllable.id}
            onClick={() => updateControllablesToControl(controllable.id)}
            key={controllable.id}
            label={controllable.name}
          />
        );
      })}

      <Button
        buttonVariant={ButtonVariant.GREEN}
        onClick={onSave}
        text="Save"
        disabled={isEqual(controllablesToControl, initiallyControlled)}
        loading={mutationLoading}
        error={mutationError?.message}
      />

      <Button
        buttonVariant={ButtonVariant.WHITE}
        onClick={goToOverview}
        text="Cancel"
      />
    </ScreenWrapper>
  );
};

const isEqual = (array1: string[], array2: string[]) =>
  JSON.stringify(array1.sort()) === JSON.stringify(array2.sort());
