import { ApolloError, useQuery } from "@apollo/client";
import { Spacing, TypographyVariant } from "@hark-tech/components";
import { useNavigator } from "@hark-tech/hooks";
import { useState } from "react";
import styled from "styled-components";
import { IconType, RoundButton, Typography } from "../../components";
import { ControllablesForUserDocument } from "../../generated/graphql/graphql";
import { RouteMap } from "../Routes";
import { useLocation } from "../location-provider/LocationProvider";
import { BaseHeader, ScreenWrapper } from "./ScreenWrapper";
import { NoConnections, YourConnections } from "./connections";

export const Overview = (): JSX.Element => {
  const navigate = useNavigator();
  const [error, setError] = useState<ApolloError>()

  const {data, loading} = useQuery(ControllablesForUserDocument, {
    fetchPolicy: "network-only",
    onError: (error) => {
      if (error.message !== "User must sign in with mill credentials before accessing the Mill API") {
        setError(error)
      }
    },
  })

  const { location } = useLocation();

  const onSettingsClicked = () => {
    navigate.to(RouteMap.location.update.basePath)
  };

  return (
    <ScreenWrapper
      header={
        <BaseHeader hasBackButton={false} title={<TextWrapper>
          <Typography
            variant={TypographyVariant.HEADER}
            text={location.name}
            emphasized
          />
          <Typography
            variant={TypographyVariant.LABEL}
            text={`${location.hourlyEnergyConsumptionLimit} Wh-limit`}
            emphasized
          />
        </TextWrapper>} rightButton={<RoundButton icon={{size: 12, type: IconType.SETTINGS}} onClick={onSettingsClicked}/>}
        />
      }
      childSpacing={Spacing.MEDIUM}
      queryError={error}
      queryLoading={loading}
    >
      {data ? <YourConnections connections={data.listControllablesForUser.controllables} controllablesOnLocation={location.controllableIds as string[] ?? []} />
        :
        <NoConnections />
      }

    </ScreenWrapper>
  );
};

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`