import { useQuery } from "@apollo/client";
import { PropsWithChildren, createContext, useContext } from "react";
import { Loader, Typography } from "../../components";
import { Location, LocationsDocument } from "../../generated/graphql/graphql";
import { AddDevice } from "./AddDevice";
import { AddHourlyConsumptionLimit } from "./AddHourlyConsumptionLimit";
import { NoLocationsYet } from "./NoLocationsYet";

interface LocationProviderContext {
  location: Location;
  refetch: () => Promise<void>;
}
const LocationProviderContext = createContext<LocationProviderContext | undefined>(undefined);

export const LocationProvider = ({
  children,
}: PropsWithChildren<unknown>): JSX.Element => {
  const { data, loading, error, refetch } = useQuery(LocationsDocument, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const reload = async () => {
    await refetch();
  };

  if (loading) {
    return <Loader size="FULLSCREEN" />;
  }

  if (error) {
    return <Typography text="Noe gikk galt" />;
  }

  if (!data || data?.locations.length === 0) {
    return <NoLocationsYet onFinish={reload} />;
  }

  const [location] = data.locations;

  if (!location.deviceId) {
    return <AddDevice locationId={location.id} onFinish={reload} />;
  }

  if (!location.hourlyEnergyConsumptionLimit) {
    return (
      <AddHourlyConsumptionLimit locationId={location.id} onFinish={reload} />
    );
  }

  return (
    <LocationProviderContext.Provider
      value={{
        location,
        refetch: reload,
      }}
    >
      {children}
    </LocationProviderContext.Provider>
  );
};

export const useLocation = (): LocationProviderContext => {
  const context = useContext(LocationProviderContext);

  if (!context) {
    throw new Error(`useLocations must be called inside an LocationProvider`);
  }

  return context;
};
