import { Input as BaseInput, InputType } from "@hark-tech/components";
import { PropsWithChildren } from "react";
import { useTheme } from "styled-components";

interface InputProps {
  id: string;
  focus?: boolean;
  type?: InputType;
  label?: string;
  value: string;
  onChange: (newValue: string) => void;
}

export const Input = ({
  id,
  focus,
  type,
  label,
  value,
  onChange,
}: PropsWithChildren<InputProps>): JSX.Element => {
  const theme = useTheme();
  return (
    <BaseInput
      id={id}
      label={{
        style: {
          color: theme.colors.text["500"],
          fontSize: "12px",
          fontWeight: 900,
          marginBottom: "8px",
          textTransform: "uppercase",
        },
        text: label,
      }}
      focus={focus}
      value={value}
      type={type}
      style={{
        backgroundColor: theme.colors.text["100"],
        border: "none",
        borderRadius: "4px",
        color: theme.colors.text["500"],
        fontSize: "16px",
        height: "40px",
        padding: "8px",
      }}
      onChange={onChange}
    />
  );
};
