/* eslint-disable @typescript-eslint/naming-convention */
import { DefaultTheme } from "styled-components";
import { ThemeType } from "./ThemeType";

export const DarkTheme: DefaultTheme = {
  colors: {
    background: {
      "300": "rgba(255, 255, 255, 0.15)",
      "500": "rgba(255, 255, 255, 0.05)",
    },

    danger: {
      "300": "rgba(255, 160, 183, 0.25)",
      "500": "rgba(255, 160, 183, 1)",
    },

    modalBackground: {
      "500": "#444444",
    },

    success: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      "300": "rgba(64, 229, 170, 0.5)",
      // eslint-disable-next-line @typescript-eslint/naming-convention
      "500": "rgba(64, 229, 170, 1)",
      // eslint-disable-next-line @typescript-eslint/naming-convention
      "900": "#1D8969",
    },

    text: {
      "100": "rgba(255, 255, 255, 0.0375)",
      "200": "rgba(255, 255, 255, 0.05)",
      "300": "rgba(255, 255, 255, 0.5)",
      "500": "#fff",
    },

    warning: {
      "500": "rgba(255, 243, 165, 1)",
    },
  },
  type: ThemeType.DARK,
};
