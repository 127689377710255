import { useOnClickOutside } from "@hark-tech/hooks";
import React, { PropsWithChildren, useRef } from "react";
import styled, { CSSProperties } from "styled-components";

const Wrapper = styled.div<{
  $open: boolean;
}>`
  position: fixed;
  display: ${(props) => (props.$open ? "flex" : "none")};
  justify-content: center;
  align-items: center;

  z-index: 1;

  top: 0;
  left: 0;

  background: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
`;

const ModalWrapper = styled.div`
  position: relative;

  background-color: white;

  @media (max-width: 480px) {
    height: 100%;
    width: 100%;

    overflow: scroll;
  }
`;

const CloseButton = styled.div`
  position: absolute;
  right: 1rem;
  top: 1rem;
  cursor: pointer;
`;

export interface ModalProps {
  open: boolean;
  style?: CSSProperties;
  backgroundStyle?: CSSProperties;
  closeButton?: boolean | JSX.Element;
  onClose: () => void;
}

export const Modal = ({
  open,
  style,
  backgroundStyle,
  closeButton,
  onClose,
  children,
}: PropsWithChildren<ModalProps>): JSX.Element => {
  const ref = useRef(null);

  useOnClickOutside(ref, onClose);

  window.addEventListener("keydown", function (event) {
    if (event.key === "Escape") {
      onClose();
    }
  });

  return (
    <Wrapper $open={open} style={backgroundStyle}>
      <ModalWrapper ref={ref} style={style}>
        {closeButton !== false && (
          <CloseButton onClick={onClose}>
            {closeButton === true ? "x" : closeButton}
          </CloseButton>
        )}

        {children}
      </ModalWrapper>
    </Wrapper>
  );
};
