import { Spacing } from "@hark-tech/components";
import {
  Button,
  ButtonVariant,
  Input,
  LatLng,
  LatLngSelection,
} from "../../components";
import { BaseHeader, ScreenWrapper } from "../smart-control";

interface LocationNameAndCoordinatesFormProps {
  header: string;
  name: string;
  setName: (v: string) => void;
  latLng?: LatLng;
  setLatLng: (v: LatLng) => void;
  disabled?: boolean;
  loading?: boolean;
  error?: string;
  onBackClick: () => void;
  onSave: () => void;
}

export const LocationNameAndCoordinatesForm = ({
  header,
  name,
  latLng,
  setName,
  setLatLng,
  disabled,
  loading,
  error,
  onBackClick,
  onSave,
}: LocationNameAndCoordinatesFormProps): JSX.Element => {
  return (
    <ScreenWrapper
      header={<BaseHeader title={header} rightButton="1/3" />}
      childSpacing={Spacing.MEDIUM}
    >
      <LatLngSelection initialLatLng={latLng} onLatLngChanged={setLatLng} />

      <Input id="name" value={name} onChange={setName} label="Display name" />

      <Button
        buttonVariant={ButtonVariant.GREEN}
        onClick={onSave}
        text="Continue"
        disabled={disabled}
        loading={loading}
        error={error}
      />

      <Button
        buttonVariant={ButtonVariant.WHITE}
        onClick={onBackClick}
        text="Cancel"
      />
    </ScreenWrapper>
  );
};
