import { TypographyVariant } from "@hark-tech/components";
import { useNavigator } from "@hark-tech/hooks";
import styled, { DefaultTheme, useTheme } from "styled-components";
import { Typography } from "../../../components";
import { Controllable, Vendor } from "../../../generated/graphql/graphql";
import { RouteMap } from "../../Routes";

interface ConnectionEntryProps {
  vendor: Vendor;
  connection: Controllable[];
  controllables: string[];
}

export const ConnectionEntry = ({vendor, connection, controllables}: ConnectionEntryProps): JSX.Element => {
  const navigate = useNavigator();
  const theme = useTheme();

  return (
    <EntryWrapper onClick={() => navigate.to(RouteMap.connections.update.createPathForVendor(vendor))}>
      <Typography variant={TypographyVariant.HEADER} text={vendor} />

      <Entries>
        {connection.map(controllable => {return <Entry key={controllable.id} $isControllable={controllables.includes(controllable.id)} $theme={theme}>{controllable.name}</Entry>})} 
      </Entries>
    </EntryWrapper>
  );
};

const Entries = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
`


interface EntryProps {
  $isControllable: boolean
  $theme: DefaultTheme;
}

const Entry = styled.div<EntryProps>`
    padding: 4px;
    width: auto;
    border-radius: 4px;
    background-color: ${props => props.$isControllable ? props.$theme.colors.success["500"] : props.$theme.colors.text["200"] };
    color: ${props => props.$isControllable ? props.$theme.colors.success["900"] : props.$theme.colors.text["300"] };
`

const EntryWrapper = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
    
    margin-top: 1rem;
    padding: 1rem;
    gap: 1rem;
`