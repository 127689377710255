import { useTheme } from "styled-components";
import { propsToTextColor } from "../../../util";
import { SvgIconProps } from "../Icon";

export const CheckIcon = ({ color }: SvgIconProps): JSX.Element => {
  const theme = useTheme();
  const fill = color
    ? propsToTextColor({ $textColor: color, theme: theme })
    : theme.colors.text[500];
  
  return (
    <path d="M0 6L4 10L12 2L10 0L4 6L2 4L0 6Z" fill={fill}/>
  )
};
