import { createContext, PropsWithChildren, useContext, useState } from "react";
import { AppConfig, getConfigForEnv } from "./AppConfig";

const AppConfigContext = createContext<AppConfig | undefined>(undefined);

export const AppConfigProvider = (
  props: PropsWithChildren<unknown>
): JSX.Element => {
  const [appConfig] = useState<AppConfig>(getConfigForEnv());

  return (
    <AppConfigContext.Provider value={appConfig}>
      {props.children}
    </AppConfigContext.Provider>
  );
};

export function useAppConfig(): AppConfig {
  const context = useContext(AppConfigContext);
  if (context === undefined) {
    throw new Error(
      "useAppConfig must be called within an <AppConfigProvider>"
    );
  }
  return context;
}
