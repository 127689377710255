import { PropsWithChildren, useEffect } from "react";
import { useLocation } from "react-router";
import { useLocalStorage } from "react-use";
import {
  DefaultTheme,
  ThemeProvider as StyledThemeProvider,
} from "styled-components";
import { DarkTheme } from "./DarkTheme";
import { LightTheme } from "./LightTheme";

const DEFAULT_THEME = DarkTheme;

export const ThemeProvider = (
  props: PropsWithChildren<unknown>
): JSX.Element => {
  const [theme, setTheme] = useLocalStorage<DefaultTheme>("THEME");

  const query = useQuery();
  useEffect(() => {
    const queryTheme = query.get("theme");
    if (!queryTheme) {
      return;
    }

    if (queryTheme === "dark") {
      setTheme(DarkTheme);
    } else if (queryTheme === "light") {
      setTheme(LightTheme);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledThemeProvider theme={theme || DEFAULT_THEME}>
      {props.children}
    </StyledThemeProvider>
  );
};

function useQuery() {
  return new URLSearchParams(useLocation().search);
}
