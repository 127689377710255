import { useMutation, useQuery } from "@apollo/client";
import { RadioButtonGroup, Spacing } from "@hark-tech/components";
import { useState } from "react";
import { useTheme } from "styled-components";
import { Button, ButtonVariant, ErrorText } from "../../components";
import {
  MyDevicesDocument,
  SetDeviceForLocationDocument,
} from "../../generated/graphql/graphql";
import { BaseHeader, ScreenWrapper } from "../smart-control";

interface AddDeviceProps {
  deviceId?: string;
  locationId: string;
  onBackClick?: () => void;
  onFinish: () => void;
}

export const AddDevice = ({
  deviceId: initDeviceId,
  locationId,
  onBackClick,
  onFinish,
}: AddDeviceProps): JSX.Element => {
  const theme = useTheme();
  const [deviceId, setDeviceId] = useState<string>(initDeviceId ?? "");

  const {
    data,
    loading: queryLoading,
    error: queryError,
  } = useQuery(MyDevicesDocument);

  const [setDeviceForLocation, { loading, error }] = useMutation(
    SetDeviceForLocationDocument,
    {
      onCompleted: () => {
        onFinish();
      },
      variables: {
        input: {
          deviceIdentifier: { deviceId },
          locationId,
        },
      },
    }
  );

  return (
    <ScreenWrapper
      header={
        <BaseHeader
          title={!initDeviceId ? "Add new location" : "Update location"}
          rightButton="2/3"
        />
      }
      queryError={queryError}
      queryLoading={queryLoading}
      childSpacing={Spacing.MEDIUM}
    >
      {!data || data?.myDevices.length === 0 ? (
        <ErrorText text="You have no devices we could connect with your location" />
      ) : (
        <RadioButtonGroup
          label={{
            style: {
              color: theme.colors.text[500],
              fontSize: "12px",
              fontWeight: 900,
              marginBottom: "8px",
              textTransform: "uppercase",
            },
            text: "Connect to HAN-reader",
          }}
          options={data.myDevices.map((device) => ({
            id: device.deviceId,
            label: device.deviceName ?? "",
          }))}
          optionsLabelStyle={{ color: theme.colors.text[500], fontWeight: 900 }}
          initiallyChecked={deviceId}
          optionsStyle={{
            background: theme.colors.background[300],
            borderRadius: "4px",
            padding: "1rem",
          }}
          radioColors={{border: theme.colors.text["500"]}}
          onChange={(evt) => setDeviceId(evt.target.value)}
        />
      )}
      <Button
        buttonVariant={ButtonVariant.GREEN}
        onClick={setDeviceForLocation}
        text="Continue"
        disabled={deviceId == null}
        loading={loading}
        error={error?.message}
      />

      <Button
        buttonVariant={ButtonVariant.WHITE}
        disabled={!onBackClick}
        onClick={() => (onBackClick ? onBackClick() : undefined)}
        text="Cancel"
      />
    </ScreenWrapper>
  );
};
