import { useTheme } from "styled-components";
import { propsToTextColor } from "../../../util";
import { SvgIconProps } from "../Icon";

export const PlusIcon = ({ color }: SvgIconProps): JSX.Element => {
  const theme = useTheme();
  const fill = color
    ? propsToTextColor({ $textColor: color, theme: theme })
    : theme.colors.text[500];

  return (
    <path fillRule="evenodd" clipRule="evenodd" d="M4.87494 0H7.12479V4.87469H11.9995V7.12455H7.12479V11.9992H4.87494V7.12455H0.000244141L0.000244239 4.87469H4.87494V0Z" fill={fill}/>
  );
};
