import { useNavigator } from "@hark-tech/hooks";
import { RouteMap } from "../Routes";
import { AddHourlyConsumptionLimit } from "../location-provider/AddHourlyConsumptionLimit";
import { useLocation } from "../location-provider/LocationProvider";

export const UpdateLocationHourlyConsumptionLimit = (): JSX.Element => {
  const navigate = useNavigator();
  const { location, refetch } = useLocation();

  const onSave = () => {
    refetch();
    navigate.to(RouteMap.base);
  };

  return (
    <AddHourlyConsumptionLimit
      locationId={location.id}
      hourlyConsumptionLimit={
        location.hourlyEnergyConsumptionLimit ?? undefined
      }
      onBackClick={() => navigate.back()}
      onFinish={() => onSave()}
    />
  );
};
