import React, { CSSProperties, PropsWithChildren } from "react";
import styled from "styled-components";
import { Spacing } from "./constraints";
import { HorizontalAlignment, VerticalAlignment, mixins } from "./mixins";
import { Padding } from "./mixins/Padding";

interface Props extends StyledRowProps {
  style?: CSSProperties;
  onClick?: () => void;
}

export const Row = ({
  children,
  style,
  onClick,
  ...styledRowProps
}: PropsWithChildren<Props>): JSX.Element => {
  return (
    <StyledRow style={style} onClick={onClick} {...styledRowProps}>
      {children}
    </StyledRow>
  );
};

interface StyledRowProps {
  $padding?: Padding;
  $childSpacing?: Spacing;
  $marginTop?: Spacing;
  $horizontalAlignChildren?: HorizontalAlignment;
  $verticalAlignChildren?: VerticalAlignment;
}

const StyledRow = styled.div<StyledRowProps>`
  display: flex;
  flex-direction: row;

  ${({ $marginTop: marginTop }) => marginTop && `margin-top: ${marginTop};`}

  ${({ $horizontalAlignChildren }) =>
    horizontalAlignmentToCss($horizontalAlignChildren)}
  ${({ $verticalAlignChildren }) =>
    verticalAlignmentToCss($verticalAlignChildren)}

  ${(props) => mixins.padding(props.$padding)}
  ${(props) => mixins.horizontalChildSpacing(props.$childSpacing)}
`;

const horizontalAlignmentToCss = (
  horizontalAlignChildren?: HorizontalAlignment
) => {
  switch (horizontalAlignChildren) {
    case HorizontalAlignment.CENTER:
      return "justify-content: center;";
  }
};

const verticalAlignmentToCss = (alignment?: VerticalAlignment) => {
  switch (alignment) {
    case VerticalAlignment.CENTER:
      return "align-items: center;";
  }
};
