import { useTheme } from "styled-components";
import { TextColor, propsToTextColor } from "../util/propsToTextColor";
import { Typography } from "./Typography";

interface Props {
  text?: string;
}

export const ErrorText = ({ text }: Props): JSX.Element | null => {
  const theme = useTheme();
  return text ? (
    <Typography
      text={text}
      style={{
        color: propsToTextColor({ $textColor: TextColor.DANGER, theme }),
      }}
      emphasized
    />
  ) : null;
};
