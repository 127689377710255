import { Col, Spacing, TextAlign, TypographyVariant } from "@hark-tech/components";
import { useTheme } from "styled-components";
import { IconType, RoundButton, Typography } from "../../../components";
import { TextColor } from "../../../util";
import { ListOfConnectionVendors } from "./ListOfConnectionVendors";


export const NoConnections = (): JSX.Element => {
  const theme = useTheme();

  return (
    <div>
      <Col 
        style={{alignItems: "center", display: "flex"}} $childSpacing={Spacing.MEDIUM}>
        <RoundButton icon={{color: TextColor.PRIMARY, size: 12, type: IconType.CHECK}} backgroundColor={theme.colors.success["500"]} onClick={() => undefined}/>

        <Typography text="You've just added a location!" variant={TypographyVariant.HEADER} emphasized />

        <Typography text="Start saving by clicking on a vendor and set up your integrations." textAlign={TextAlign.CENTER} />

        <ListOfConnectionVendors />
      </Col>

    </div>
  );
};

