import { CSSProperties } from "styled-components";

export function inlineRules(rulesObj: CSSProperties) {
    return Object.entries(rulesObj).map(([property, value]) => {
        const cssProperty = property.split("").map(letter => {
            if (letter === letter.toLocaleUpperCase()) {
            return "-" + letter.toLocaleLowerCase();
        }
    return letter}).join("");
        return `${cssProperty}: ${value} !important;`}).join('');
  }