import { useTheme } from "styled-components";
import { propsToTextColor } from "../../../util";
import { SvgIconProps } from "../Icon";

export const SettingsIcon = ({ color }: SvgIconProps): JSX.Element => {
  const theme = useTheme();
  const fill = color
    ? propsToTextColor({ $textColor: color, theme: theme })
    : theme.colors.text[500];

  return (
    <path fillRule="evenodd" clipRule="evenodd" d="M9 11.197L12 6.00084L9 0.804688H3L0 6.00084L3 11.197H9ZM6 8.30469C7.24264 8.30469 8.25 7.29733 8.25 6.05469C8.25 4.81205 7.24264 3.80469 6 3.80469C4.75736 3.80469 3.75 4.81205 3.75 6.05469C3.75 7.29733 4.75736 8.30469 6 8.30469Z" fill={fill}/>
  );
};
