import { Spacing } from "@hark-tech/components";
import { useNavigator } from "@hark-tech/hooks";
import { Button, ButtonVariant, Typography } from "../../../components";
import { BaseHeader, ScreenWrapper } from "../ScreenWrapper";

export const DeleteConnection = (): JSX.Element => {
  const navigate = useNavigator();

  return (
    <ScreenWrapper
      header={
        <BaseHeader title={"Delete connection"} />
      }
      childSpacing={Spacing.SMALL}
    >
      <Typography text="Disconnect from a vendor." />


      <Button buttonVariant={ButtonVariant.GREEN} onClick={() => console.log("TODO")} text="Continue" />

      <Button buttonVariant={ButtonVariant.WHITE} onClick={() => navigate.back()} text="Cancel"/>
    </ScreenWrapper>
  );
};

