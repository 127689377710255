import React from "react";
import styled from "styled-components";
import { Label, LabelProps } from "./Label";

interface ToggleProps {
  id: string;
  checked: boolean;
  size?: "SMALL" | "MEDIUM" | "LARGE";
  colors?: {
    unToggledBackground?: string;
    unToggledToggle?: string;
    toggledBackground?: string;
    toggledToggle?: string;
  };
  label?: LabelProps;
  onToggled: () => void;
}

const toggleSizes = {
  LARGE: {
    height: "24px",
    width: "48px",
  },
  MEDIUM: {
    height: "16px",
    width: "32px",
  },
  SMALL: {
    height: "12px",
    width: "24px",
  },
};

const defaultColors = {
  toggledBackground: "rgba(0, 190, 156, 0.3)",
  toggledToggle: "rgba(0, 190, 156, 1)",
  unToggledBackground: "#f3f3f3",
  unToggledToggle: "#383636",
};
export const Toggle = ({
  id,
  size = "MEDIUM",
  colors = defaultColors,
  onToggled,
  checked,
  label,
}: ToggleProps): JSX.Element => {
  const { height, width } = toggleSizes[size];

  const toggle = (
    <ToggleContainer $height={height} $width={width}>
      <StyledToggle
        checked={checked}
        onChange={onToggled}
        type="checkbox"
        id={id}
        $height={height}
        $width={width}
        $lightColor={colors.toggledBackground}
        $darkColor={colors.toggledToggle}
      />

      <ToggleLabel
        $height={height}
        $width={width}
        $lightColor={colors.unToggledBackground}
        $darkColor={colors.unToggledToggle}
        htmlFor={id}
      />
    </ToggleContainer>
  );

  return (
    <ToggleRow
      style={{
        display: "grid",
        gridTemplateAreas: "toggle label",
        gridTemplateColumns: "auto 1fr",
        alignItems: "center",
      }}
    >
      {label ? (
        <Label
          for={id}
          focusStyle={label?.focusStyle}
          style={label?.style}
          text={label?.text}
        >
          {toggle}
        </Label>
      ) : (
        toggle
      )}
    </ToggleRow>
  );
};

interface SizeProps {
  $width: string;
  $height: string;
}

const ToggleRow = styled.div`
  display: grid;
  grid-template-areas: "toggle label";
  grid-template-columns: auto 1fr;
  grid-gap: 1rem;
  width: 100%;

  align-items: center;
  color: black;

  cursor: pointer;
`;

const ToggleContainer = styled.div<SizeProps>`
  grid-area: toggle;

  position: relative;
  width: ${(props) => props.$width};
  height: ${(props) => props.$height};
`;

interface SizeAndColorProps extends SizeProps {
  $lightColor?: string;
  $darkColor?: string;
}

const ToggleLabel = styled.label<SizeAndColorProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: ${(props) => props.$width};
  height: ${(props) => props.$height};
  border-radius: 16px;
  background: ${(props) => props.$lightColor ?? "#f3f3f3"};
  box-shadow: inset 1px 1px 1px rgba(0, 0, 0, 0.25);

  &::after {
    content: "";
    display: block;
    border-radius: 50%;
    width: ${(props) => props.$height};
    height: ${(props) => props.$height};
    background: ${(props) => props.$darkColor ?? "#383636"};
    transition: 0.2s;
  }
`;

const StyledToggle = styled.input<SizeAndColorProps>`
  opacity: 0;
  z-index: 1;
  border-radius: 16px;
  width: ${(props) => props.$width};
  height: ${(props) => props.$height};

  &:checked + ${ToggleLabel} {
    background: ${(props) => props.$lightColor ?? "rgba(0, 190, 156, 0.3)"};

    &::after {
      background: ${(props) => props.$darkColor ?? "rgba(0, 190, 156, 1)"};
      margin-left: ${(props) => props.$height};
    }
  }
`;
