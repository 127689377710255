import { ApolloError } from "@apollo/client";
import { Col, Row, Spacing, TextAlign, TypographyVariant, VerticalAlignment } from "@hark-tech/components";
import { useNavigator } from "@hark-tech/hooks";
import { PropsWithChildren } from "react";
import { ErrorText, IconType, Loader, RoundButton, Typography } from "../../components";

interface ScreenWrapperProps {
  header: JSX.Element;
  queryLoading?: boolean;
  queryError?: ApolloError;
  childSpacing?: Spacing;
}

export const ScreenWrapper = ({
  header,
  queryLoading,
  queryError,
  childSpacing = Spacing.TINY,
  children,
}: PropsWithChildren<ScreenWrapperProps>): JSX.Element => {
  return (
    <Col $childSpacing={childSpacing} style={{ height: "100%", width: "100%" }}>
      {header}
      {queryLoading ? (
        <Loader size="FULLSCREEN" />
      ) : queryError ? (
        <ErrorText text={queryError.toString()} />
      ) : (
        children
      )}
    </Col>
  );
};

interface BaseHeaderProps {
  title: string | JSX.Element;
  hasBackButton?: boolean;
  onBackClick?: () => void;
  rightButton?: JSX.Element | string;
}

export const BaseHeader = ({
  title,
  hasBackButton = true,
  rightButton,
  onBackClick: initOnBackClick,
}: BaseHeaderProps): JSX.Element => {
  const navigate = useNavigator();

  const onBackClick = initOnBackClick ? initOnBackClick : () => navigate.back();

  const styledRightButton: JSX.Element = rightButton ? (
    typeof rightButton === "string" ? (
      <RoundButton onClick={() => undefined} style={{ fontWeight: 900 }}>
        {rightButton}
      </RoundButton>
    ) : (
      rightButton
    )
  ) : (
    <div />
  );

  const styledTitle =
    typeof title === "string" ? (
      <Typography
        variant={TypographyVariant.HEADER}
        textAlign={TextAlign.CENTER}
        text={title}
        emphasized
      />
    ) : (
      title
    );

  return (
    <Row
      style={{
        display: "grid",
        gridTemplateColumns: "32px 1fr 32px",
        justifyContent: "center",
        width: "100%",
      }}
      $verticalAlignChildren={VerticalAlignment.CENTER}
    >
      {hasBackButton ? (
        <RoundButton icon={{ type: IconType.BACK }} onClick={onBackClick} />
      ) : (
        <div />
      )}

      {styledTitle}

      {styledRightButton}
    </Row>
  );
};
