import React, { PropsWithChildren } from "react";
import styled, { CSSProperties } from "styled-components";
import { inlineRules } from "../utils";

export interface LabelProps {
  text?: string;
  for?: string;
  focusStyle?: CSSProperties;
  style?: CSSProperties;
}

interface LabelNonOptionalForProps extends LabelProps {
  for: string;
}

export const Label = ({
  for: $for,
  text,
  style,
  focusStyle,
  children,
}: PropsWithChildren<LabelNonOptionalForProps>) => {
  return (
    <LabelContainer $focusStyle={focusStyle}>
      <label htmlFor={$for} style={style}>
        {text}
      </label>
      {children}
    </LabelContainer>
  );
};

const LabelContainer = styled.div<{ $focusStyle?: CSSProperties }>`
  display: contents;
  grid-area: label;

  label {
    cursor: pointer;
  }

  &:focus-within label {
    ${(props) => props.$focusStyle && inlineRules(props.$focusStyle)};
  }
`;
