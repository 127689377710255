import React from "react";
import styled from "styled-components";
import { TextAlign } from "./TextAlign";

type TypographyTag = "h1" | "h2" | "h3" | "p";

export enum TypographyVariant {
  HEADER = "HEADER",
  SUB_HEADER = "SUB_HEADER",
  PARAGRAPH = "PARAGRAPH",
  DETAIL = "DETAIL",
  LABEL = "LABEL",
  NUMBER = "NUMBER",
  NUMBER_SMALL = "NUMBER_SMALL",
  BUTTON = "BUTTON",
}

export interface TypographyProps {
  textAlign?: TextAlign;
  centerVertically?: boolean;
  emphasized?: boolean;
  tag?: TypographyTag;
  text: string;
  variant?: TypographyVariant;
  style?: React.CSSProperties;
}

export const Typography = ({
  centerVertically = false,
  emphasized,
  tag = "p",
  style: customStyle,
  text,
  textAlign,
  variant = TypographyVariant.PARAGRAPH,
}: TypographyProps): JSX.Element => {
  const DynamicComponent = getStyleForVariant({ variant });

  // eslint-disable-next-line no-magic-numbers
  const fontWeight = emphasized ? "bold" : 400;

  const style = centerVertically
    ? { alignItems: "center", display: "flex", ...customStyle }
    : customStyle;

  return (
    <DynamicComponent as={tag} style={{ fontWeight, textAlign, ...style }}>
      {text}
    </DynamicComponent>
  );
};

const getStyleForVariant = ({ variant }: { variant: TypographyVariant }) => {
  switch (variant) {
    case TypographyVariant.HEADER:
      return HeaderStyle;

    case TypographyVariant.SUB_HEADER:
      return SubHeaderStyle;

    case TypographyVariant.DETAIL:
      return DetailStyle;

    case TypographyVariant.LABEL:
      return LabelStyle;

    case TypographyVariant.NUMBER:
      return NumberStyle;

    case TypographyVariant.NUMBER_SMALL:
      return NumberSmallStyle;

    case TypographyVariant.BUTTON:
      return ButtonStyle;

    default:
      return ParagraphStyle;
  }
};

const HeaderStyle = styled.div`
  font-size: 1.1rem;
  line-height: 1.3rem;
`;

const SubHeaderStyle = styled.div`
  font-size: 1.125rem;
  line-height: 1.125rem;
  font-variant-caps: all-small-caps;
  font-weight: 600;
  letter-spacing: 0.08rem;
`;

const DetailStyle = styled.div`
  font-size: 0.9rem;
  line-height: 1rem;
  letter-spacing: 0.02em;
  font-variant-caps: all-small-caps;
`;

const ParagraphStyle = styled.div`
  font-size: 0.9rem;
  line-height: 1rem;
  font-weight: normal;
  letter-spacing: 0.02em;
`;

const LabelStyle = styled.div`
  letter-spacing: -0.02em;
  font-size: 0.9rem;
  line-height: 1rem;
  font-weight: bold;
`;

const NumberStyle = styled.div`
  font-size: 1.125rem;
  line-height: 1.125rem;
  font-weight: 900;
`;

const NumberSmallStyle = styled.div`
  font-size: 1rem;
  line-height: 1rem;
  font-weight: 900;
`;

const ButtonStyle = styled.div`
  font-size: 0.875rem;
  line-height: 0.875rem;
  letter-spacing: 0.12rem;
  font-variant-caps: all-small-caps;
`;
