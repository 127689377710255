import { useMutation } from "@apollo/client";
import { useState } from "react";
import { Button, ButtonVariant, ErrorText } from "../../../components";
import { IntegrationType, StartIntegrationSessionDocument, Vendor } from "../../../generated/graphql/graphql";
import { RouteMap } from "../../Routes";


export const ListOfConnectionVendors = (): JSX.Element => {
  const [error, setError] = useState<string>();

  const [startIntegrationSession, { loading }] = useMutation(
    StartIntegrationSessionDocument, {
      onCompleted: (data) => {
        window.location.href = data.startIntegrationSession.url;
      },
      onError: (err) => {
        setError(err.message)
      }
    }
  );  
  
  const onIntegrationClick = (vendor: Vendor) => {
    const redirectUrl = createRedirectUrl(window.location.href, RouteMap.connections.describe.createPathForVendor(vendor));
    const integration = vendorToIntegrationType(vendor);
    if (integration == null) {
      return;
    }
    startIntegrationSession({variables: {input: {integration, redirectUrl}}})
  }

  // Each time we add a new vendor, this list needs to be updated.
  const vendors: Vendor[] = ["ADAX", "MILL"];
  const vendorToIntegrationType = (vendor: Vendor): IntegrationType | undefined => {
    switch (vendor) {
      case "MILL":
        return "MILL";
      default:
        setError(`Could not connect to "${vendor}"`);
        break;
    }
  }

  return (
    <>
      {error && <ErrorText text={error} />}
      {vendors.map(vendor => 
        <Button buttonVariant={ButtonVariant.WHITE} text={vendor} onClick={() => onIntegrationClick(vendor)} loading={loading} key={vendor} />
      )}
    </>
  );
};


const createRedirectUrl = (href: string, path: string) => {
  const hrefWithProtocol = href.includes("://") ? href : ("https://" + href);
  const hrefWithoutTrailingSlash = hrefWithProtocol.endsWith("/") ? hrefWithProtocol.slice(0, -1) : hrefWithProtocol;
  return hrefWithoutTrailingSlash + path;
}