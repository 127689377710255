import React from "react";
import styled, { CSSProperties } from "styled-components";
import { Label, LabelProps } from "./Label";
import { RadioButton, RadioButtonColors } from "./radio-button";

interface IOption {
  id: string;
  label?: string;
  disabled?: boolean;
}

interface RadioButtonGroupProps {
  label?: LabelProps;
  optionsLabelStyle?: CSSProperties;
  optionsStyle?: CSSProperties;
  radioColors?: RadioButtonColors;
  options: IOption[];
  initiallyChecked?: string;
  loading?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const RadioButtonGroup = ({
  label,
  options,
  initiallyChecked,
  loading,
  optionsLabelStyle,
  optionsStyle,
  radioColors,
  onChange,
}: RadioButtonGroupProps) => {
  const radios = (
    <Wrapper>
      <RenderOptions
        key="radios"
        options={options}
        defaultChecked={initiallyChecked}
        loading={loading}
        listStyle={optionsStyle}
        labelStyle={optionsLabelStyle}
        radioColors={radioColors}
        onChange={onChange}
      />
    </Wrapper>
  );
  return (
    <RadioButtonGroupContainer>
      {label ? (
        <Label
          for="radios"
          focusStyle={label.focusStyle}
          text={label.text}
          style={label.style}
        >
          {radios}
        </Label>
      ) : (
        radios
      )}
    </RadioButtonGroupContainer>
  );
};

const RadioButtonGroupContainer = styled.div`
  display: grid;
  grid-template-areas:
    "label"
    "radios";
  width: 100%;
`;

interface RenderOptionsProps {
  options: IOption[];
  defaultChecked?: string;
  loading?: boolean;
  labelStyle?: CSSProperties;
  listStyle?: CSSProperties;
  radioColors?: RadioButtonColors;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const RenderOptions = ({
  options,
  defaultChecked,
  loading,
  labelStyle,
  listStyle,
  radioColors,
  onChange,
}: RenderOptionsProps) => {
  return (
    <>
      {options.map(({ id, label, disabled }) => {
        const optionId = `radio-option-${id}`;

        return (
          <RadioButton
            value={id}
            label={{ style: labelStyle, text: label || id }}
            key={optionId}
            id={optionId}
            name="radio"
            disabled={disabled || loading}
            defaultChecked={defaultChecked === id}
            onChange={onChange}
            style={listStyle}
            colors={radioColors}
          />
        );
      })}
    </>
  );
};

const Wrapper = styled.div`
  padding: 0.5rem;
  display: grid;
  gap: 1rem;
  grid-area: radios;
`;
