import { Col, Spacing } from "@hark-tech/components";
import { BrowserRouter } from "react-router-dom";
import { AppConfigProvider } from "../app-config/AppConfigProvider";
import { AuthProvider } from "../auth/AuthProvider";
import { GraphqlProvider } from "../graphql/GraphqlProvider";
import { ThemeProvider } from "../theme/ThemeProvider";
import { Routes } from "./Routes";
import { LocationProvider } from "./location-provider/LocationProvider";

function App(): JSX.Element {
  return (
    <Col style={{ alignItems: "center", flexGrow: 1 }}>
      <Col
        style={{
          height: "100%",
          padding: `${Spacing.SMALL} ${Spacing.MEDIUM}`,
          width: "clamp(256px, 100%, 100%)",
        }}
      >
        <BrowserRouter>
          <ThemeProvider>
            <AppConfigProvider>
              <AuthProvider>
                <GraphqlProvider>
                  <LocationProvider>
                    <Routes />
                  </LocationProvider>
                </GraphqlProvider>
              </AuthProvider>
            </AppConfigProvider>
          </ThemeProvider>
        </BrowserRouter>
      </Col>
    </Col>
  );
}

export default App;
