import { Spacing } from "../constraints/Spacing";

type VerticalHorizontalPadding = { horizontal: Spacing; vertical: Spacing };
type IndividualSidePadding = {
  top?: Spacing;
  right?: Spacing;
  bottom?: Spacing;
  left?: Spacing;
};
export type Padding =
  | Spacing
  | VerticalHorizontalPadding
  | IndividualSidePadding;

export const paddingToCss = (padding: Padding): string => {
  if (paddingIsIndividualSidePadding(padding)) {
    return `padding: ${padding.top || 0} ${padding.right || 0} ${
      padding.bottom || 0
    } ${padding.left || 0};`;
  }
  if (paddingIsVerticalHorizontal(padding)) {
    return `
	    padding: ${padding.vertical} ${padding.horizontal};
	  `;
  }

  return `padding: ${padding};`;
};

const paddingIsVerticalHorizontal = (
  padding?: Padding
): padding is VerticalHorizontalPadding => {
  return !!(padding as VerticalHorizontalPadding).horizontal;
};

const paddingIsIndividualSidePadding = (
  padding?: Padding
): padding is IndividualSidePadding => {
  return (
    !!(padding as IndividualSidePadding).top ||
    !!(padding as IndividualSidePadding).right ||
    !!(padding as IndividualSidePadding).bottom ||
    !!(padding as IndividualSidePadding).left
  );
};
