import { Col, Spacing, TypographyVariant } from "@hark-tech/components";
import { useNavigator } from "@hark-tech/hooks";
import { useParams } from "react-router-dom";
import { Button, ButtonVariant, ErrorText, Typography } from "../../../components";
import { Vendor } from "../../../generated/graphql/graphql";
import { RouteMap } from "../../Routes";


export const Description = (): JSX.Element => {
  const { vendor } = useParams<{ vendor: Vendor }>();
  const navigate = useNavigator();

  if (!vendor) {
    return <ErrorText />
  }

  return (
    <Col style={{display: "flex", height: "100%", justifyContent: "center"}} $childSpacing={Spacing.SMALL}>
      <Typography text="Choose controllable rooms" variant={TypographyVariant.HEADER} emphasized />

      <Typography text={`${vendor} has been successfully linked to your account, but we now need a little more input on which rooms to control.`} />

      <Button buttonVariant={ButtonVariant.GREEN} text="Proceed" onClick={() => navigate.to(RouteMap.connections.update.createPathForVendor(vendor))} />

      <Button buttonVariant={ButtonVariant.WHITE} text="Skip for now" onClick={() => navigate.to(RouteMap.base)} />
    </Col>
  );
};
