import {
  Row,
  Spacing,
  TypographyVariant,
  VerticalAlignment
} from "@hark-tech/components";
import { useState } from "react";
import { Typography } from "../../components";
import { ScreenWrapper } from "../smart-control";
import { AddNewLocation } from "./AddNewLocation";
import { AddNewLocationButton } from "./AddNewLocationButton";

interface NoLocationsYetProps {
  onFinish: () => void;
}

export const NoLocationsYet = ({
  onFinish,
}: NoLocationsYetProps): JSX.Element => {
  const [addOpen, setAddOpen] = useState(false);
  return (
    <>
      {!addOpen ? (
        <ScreenWrapper
          header={
            <Row
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
              $verticalAlignChildren={VerticalAlignment.CENTER}
            >
              <Typography
                variant={TypographyVariant.HEADER}
                text="Smart connections"
                emphasized
              />
            </Row>
          }
          childSpacing={Spacing.MEDIUM}
        >
          <Typography
            variant={TypographyVariant.PARAGRAPH}
            text="Start by adding a location. Smart appliances can then be added to it."
          />

          <AddNewLocationButton onClick={() => setAddOpen(true)} />
        </ScreenWrapper>
      ) : (
        <AddNewLocation
          onBackClick={() => setAddOpen(false)}
          onFinish={onFinish}
        />
      )}
    </>
  );
};
