import React, { PropsWithChildren } from "react";
import styled, { CSSProperties } from "styled-components";
import { Loader, LoaderProps } from "./Loader";

interface RoundButtonProps {
  backgroundColor?: string;
  loading?: boolean;
  disabled?: boolean;
  border?: string;
  size?: "SMALL" | "MEDIUM" | "LARGE";
  loader?: LoaderProps;
  style?: CSSProperties;
  onClick: () => void;
}

const buttonSizes = {
  LARGE: {
    height: "48px",
    width: "48px",
  },
  MEDIUM: {
    height: "32px",
    width: "32px",
  },
  SMALL: {
    height: "24px",
    width: "24px",
  },
};

export const RoundButton = ({
  backgroundColor = "white",
  loading,
  loader,
  border = "0",
  disabled,
  size = "MEDIUM",
  style,
  onClick,
  children,
}: PropsWithChildren<RoundButtonProps>): JSX.Element => {
  return (
    <Button
      disabled={disabled}
      $loading={loading}
      $border={border}
      $backgroundColor={backgroundColor}
      style={{ ...buttonSizes[size], ...style }}
      onClick={onClick}
    >
      {loading ? <Loader size="SMALL" {...loader} /> : children}
    </Button>
  );
};

interface StyledRoundButtonProps {
  $backgroundColor: string;
  $border: string;
  $loading?: boolean;
}

const Button = styled.button<StyledRoundButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  border: ${(props) => props.$border};
  background-color: ${(props) => props.$backgroundColor};

  cursor: pointer;

  ${(props) =>
    props.disabled &&
    `
      opacity: 0.5;
      cursor: default;
    `}

  ${(props) =>
    props.$loading &&
    `
          cursor: default;
        `}
`;
