import { Checkbox, Row } from "@hark-tech/components";
import { useTheme } from "styled-components";

interface Props {
  label: string;
  id: string;
  checked: boolean;
  onClick: () => void;
}

export const CheckboxListEntry = ({
  label,
  id,
  checked,
  onClick,
}: Props): JSX.Element => {
  const theme = useTheme();
  return (
    <Row
      style={{
        alignItems: "center",
        backgroundColor: theme.colors.text["100"],
        borderRadius: "4px",
        display: "grid",
        paddingLeft: "8px",
        width: "100%",
      }}
    >
      <Checkbox
        checked={checked}
        id={id}
        checkedCheckbox={{ color: theme.colors.success["500"] }}
        uncheckedCheckbox={{ color: theme.colors.success["500"] }}
        label={{
          style: { color: theme.colors.text["500"], fontWeight: "900" },
          text: label,
        }}
        setChecked={onClick}
      />
    </Row>
  );
};
