import React from "react";
import styled, { keyframes } from "styled-components";
import { Col } from "../Col";
import { HorizontalAlignment, VerticalAlignment } from "../mixins";
import { LoaderTypeProps, loaderSizeToLoaderWidth } from "./LoaderProps";

export const Ripples = ({
  color,
  style,
  size,
}: LoaderTypeProps): JSX.Element => {
  const loader = (
    <RippleContainer
      style={style}
      $color={color}
      $width={loaderSizeToLoaderWidth(size)}
    >
      <div />
      <div />
      <div />
    </RippleContainer>
  );
  if (size === "FULLSCREEN") {
    return (
      <Col
        $horizontalAlignChildren={HorizontalAlignment.CENTER}
        $verticalAlignChildren={VerticalAlignment.CENTER}
        style={{ height: "100%", width: "100%" }}
      >
        {loader}
      </Col>
    );
  }
  return loader;
};

const rippleKeyframes = ({ $width }: { $width: number }) => keyframes`
    0% {
      top: ${$width / 2}px;
      left: ${$width / 2}px;
      width: 0;
      height: 0;
      opacity: 1;
    }
    95% {
      top: 0;
      left: 0;
      width: ${$width}px;
      height: ${$width}px;
      opacity: 0;
    }
    // this is needed to avoid "flickering" in some browsers upon reaching 100%. In essence, we reset to the start values with 0 opacity 
    100% {
      top: ${$width / 2}px;
      left: ${$width / 2}px;
      width: 0;
      height: 0;
      opacity: 0;
    }
`;

const RippleContainer = styled.div<{ $width: number; $color?: string }>`
  display: inline-block;
  position: relative;
  width: ${(props) => props.$width}px;
  height: ${(props) => props.$width}px;

  > div {
    position: absolute;
    border: 1px solid ${(props) => props?.$color || "black"};
    opacity: 1;
    border-radius: 50%;
    animation: ${rippleKeyframes} 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }

  > div:nth-child(2) {
    animation-delay: -0.5s;
  }
`;
