import { useMutation } from "@apollo/client";
import { useState } from "react";
import { AddLocationDocument, LatLng } from "../../generated/graphql/graphql";
import { LocationNameAndCoordinatesForm } from "../location";

interface AddNewLocationProps {
  onBackClick: () => void;
  onFinish: () => void;
}

export const AddNewLocation = ({
  onBackClick,
  onFinish,
}: AddNewLocationProps): JSX.Element => {
  const [latLng, setLatLng] = useState<LatLng>();
  const [name, setName] = useState<string>("");

  const [addLocation, { loading, error }] = useMutation(AddLocationDocument, {
    onCompleted: () => {
      onFinish();
    },
    variables: {
      input: {
        coordinates: latLng as LatLng,
        name,
      },
    },
  });

  return (
    <LocationNameAndCoordinatesForm
      header="Add new location"
      latLng={latLng}
      setLatLng={setLatLng}
      name={name}
      setName={setName}
      onBackClick={onBackClick}
      onSave={addLocation}
      disabled={name === "" || !latLng}
      error={error?.message}
      loading={loading}
    />
  );
};
