import { Spacing } from "../constraints/Spacing";

export const verticalChildSpacingToCss = (
  childSpacing: ChildSpacing
): string => {
  return `
    > *:not(:first-child) {
      margin-top: ${childSpacing};
    } 
  `;
};

export const horizontalChildSpacingToCss = (
  childSpacing: ChildSpacing
): string => {
  return `
    > *:not(:first-child) {
      margin-left: ${childSpacing};
    } 
  `;
};

export type ChildSpacing = Spacing;
