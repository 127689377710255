import React from "react";
import styled, { keyframes } from "styled-components";
import { Col } from "../Col";
import { HorizontalAlignment, VerticalAlignment } from "../mixins";
import { LoaderTypeProps, loaderSizeToLoaderWidth } from "./LoaderProps";

export const Spinner = ({
  color,
  style,
  size,
}: LoaderTypeProps): JSX.Element => {
  const loader = (
    <StyledSpinner
      $color={color}
      style={style}
      $width={loaderSizeToLoaderWidth(size)}
    />
  );

  if (size === "FULLSCREEN") {
    return (
      <Col
        $horizontalAlignChildren={HorizontalAlignment.CENTER}
        $verticalAlignChildren={VerticalAlignment.CENTER}
        style={{ height: "100%", width: "100%" }}
      >
        {loader}
      </Col>
    );
  }
  return loader;
};

const spinnerKeyframes = () => keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const StyledSpinner = styled.div<{ $width: number; $color?: string }>`
  display: inline-block;
  position: relative;
  width: ${(props) => props.$width}px;
  height: ${(props) => props.$width}px;

  border: ${(props) => props.$width / 5}px solid rgba(255, 255, 255, 0.3);
  border-top: ${(props) => props.$width / 5}px solid
    ${(props) => props?.$color || "black"};
  border-radius: ${(props) => props.$width}px;

  animation: ${spinnerKeyframes} 1.5s linear infinite;
`;
