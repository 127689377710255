import React, { HTMLInputTypeAttribute } from "react";
import styled, { CSSProperties } from "styled-components";
import { Label, LabelProps } from "./Label";

export enum InputType {
  TEXT,
  NUMBER,
  PASSWORD,
  TELEPHONE_NUMBER,
}

interface Props {
  id: string;
  focus?: boolean;
  type?: InputType;
  label?: LabelProps;
  value: string;
  style?: CSSProperties;
  onChange: (newValue: string) => void;
}

export const Input = ({
  id,
  label,
  focus = false,
  value,
  type = InputType.TEXT,
  style,
  onChange,
}: Props): JSX.Element => {
  const inputType = (): HTMLInputTypeAttribute => {
    switch (type) {
      case InputType.NUMBER:
        return "number";
      case InputType.PASSWORD:
        return "password";
      case InputType.TELEPHONE_NUMBER:
        return "tel";
      default:
        return "text";
    }
  };

  const input = (
    <StyledInput
      id={id}
      value={value}
      style={style}
      autoFocus={focus}
      type={inputType()}
      onChange={(event: any) => onChange(event.target.value)}
    />
  );

  return (
    <InputContainer>
      {label ? (
        <Label
          for={id}
          focusStyle={label.focusStyle}
          text={label.text}
          style={label.style}
        >
          {input}
        </Label>
      ) : (
        input
      )}
    </InputContainer>
  );
};

const InputContainer = styled.div`
  display: grid;
  grid-template-areas:
    "label"
    "input";
  width: 100%;
`;

const StyledInput = styled.input`
  display: flex;
  align-items: center;
  grid-area: input;
`;
