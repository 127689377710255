import { Row, TypographyVariant } from "@hark-tech/components";
import { useNavigator } from "@hark-tech/hooks";
import { IconType, RoundButton, Typography } from "../../../components";
import { Controllable } from "../../../generated/graphql/graphql";
import { sortControllablesByVendor } from "../../../util";
import { RouteMap } from "../../Routes";
import { ConnectionEntry } from "./ConnectionEntry";

interface YourConnectionsProps {
  connections: Controllable[];
  controllablesOnLocation: string[];
}

export const YourConnections = ({connections, controllablesOnLocation}: YourConnectionsProps): JSX.Element => {
  const navigate = useNavigator();
  const connectionsByVendor = sortControllablesByVendor(connections);
  const listOfConnections = Array.from(connectionsByVendor, (([key, value]) => <ConnectionEntry key={`${key}+${value}`} vendor={key} connection={value} controllables={controllablesOnLocation} />))

  return (
    <>
      <Row 
        style={{alignItems: "center", display: "grid", gap: "0.5rem", gridTemplateColumns: "1fr 32px 32px"}}
      >
        <Typography text="Your connections" variant={TypographyVariant.SUB_HEADER} />

        <RoundButton icon={{size: 12, type: IconType.DELETE}} onClick={() => navigate.to(RouteMap.connections.delete)}/>

        <RoundButton icon={{size: 12, type: IconType.PLUS}} onClick={() => navigate.to(RouteMap.connections.add)}/>
      </Row>

      {listOfConnections}
    </>
  );
};
