/* eslint-disable @typescript-eslint/naming-convention */
import { DefaultTheme } from "styled-components";
import { ThemeType } from "./ThemeType";

export const LightTheme: DefaultTheme = {
  colors: {
    background: {
      "300": "rgba(0, 0, 0, 0.15)",
      "500": "rgba(0, 0, 0, 0.05)",
    },

    danger: {
      "300": "rgba(253,155,152, 0.25)",
      "500": "rgba(253,155,152, 1)",
    },

    modalBackground: {
      "500": "#ffffff",
    },

    success: {
      "300": "rgba(64, 229, 170, 0.5)",
      "500": "rgba(64, 229, 170, 1)",
      // eslint-disable-next-line @typescript-eslint/naming-convention
      "900": "#1D8969",
    },

    text: {
      "100": "rgba(68, 68, 68, 0.0375)",
      "200": "rgba(68, 68, 68, 0.05)",
      "300": "rgba(68, 68, 68, 0.5)",
      "500": "rgba(68, 68, 68, 1)",
    },

    warning: {
      "500": "rgba(255,231,171,1)",
    },
  },
  type: ThemeType.LIGHT,
};
