import { Spacing } from "@hark-tech/components";
import { Typography } from "../../../components";
import { BaseHeader, ScreenWrapper } from "../ScreenWrapper";
import { ListOfConnectionVendors } from "./ListOfConnectionVendors";

export const AddConnection = (): JSX.Element => {
  return (
    <ScreenWrapper
      header={<BaseHeader title={"Add new connection"} />}
      childSpacing={Spacing.SMALL}
    >
      <Typography text="Save more by clicking on a vendor and set up your integrations." />

      <ListOfConnectionVendors />
    </ScreenWrapper>
  );
};
