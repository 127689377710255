import {
  RoundButton as BaseRoundButton,
  LoaderProps,
} from "@hark-tech/components";
import { PropsWithChildren } from "react";
import { CSSProperties, useTheme } from "styled-components";
import { Loader } from "./Loader";
import { Icon, IconProps } from "./icon";

interface RoundButtonProps {
  backgroundColor?: string;
  loading?: boolean;
  disabled?: boolean;
  border?: string;
  size?: "SMALL" | "MEDIUM" | "LARGE";
  loader?: LoaderProps;
  style?: CSSProperties;
  icon?: IconProps;
  onClick: () => void;
}

export const RoundButton = ({
  backgroundColor,
  loading,
  loader,
  border = "0",
  disabled,
  size,
  style,
  icon,
  onClick,
  children,
}: PropsWithChildren<RoundButtonProps>): JSX.Element => {
  const theme = useTheme();
  return (
    <BaseRoundButton
      disabled={disabled}
      loading={loading}
      border={border}
      backgroundColor={
        backgroundColor ? backgroundColor : theme.colors.text["200"]
      }
      size={size}
      style={{ color: theme.colors.text["500"], ...style }}
      onClick={onClick}
    >
      {loading ? (
        <Loader size="SMALL" {...loader} />
      ) : icon ? (
        <Icon centerVertically {...icon} />
      ) : (
        children
      )}
    </BaseRoundButton>
  );
};
