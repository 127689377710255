import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { PropsWithChildren, useMemo } from "react";
import { useAppConfig } from "../app-config/AppConfigProvider";
import { useAuth } from "../auth/AuthProvider";

export const GraphqlProvider = ({
  children,
}: PropsWithChildren<unknown>): JSX.Element => {
  const { accessToken } = useAuth();
  const appConfig = useAppConfig();

  const httpLink = useMemo(() => {
    return createHttpLink({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      headers: { "Content-Type": "application/json" },
      uri: appConfig.graphqlUri,
    });
  }, [appConfig]);

  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${accessToken}`,
      },
    };
  });

  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: authLink.concat(httpLink),
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
