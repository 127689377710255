import React, { InputHTMLAttributes } from "react";
import styled, { CSSProperties } from "styled-components";
import { Label, LabelProps } from "../Label";

export interface RadioButtonProps
  extends InputHTMLAttributes<HTMLInputElement> {
  label: LabelProps;
  id: string;
  disabled?: boolean;
  style?: CSSProperties;
  colors?: RadioButtonColors;
}

export interface RadioButtonColors {
  background?: string;
  border?: string;
  checked?: string;
  focus?: string;
}

const defaultColors: RadioButtonColors = {
  background: "#f3f3f3",
  border: "#383636",
  checked: "rgba(0, 190, 156, 1)",
  focus: "rgba(0, 190, 156, 0.3)",
};

export const RadioButton = ({
  label,
  id,
  disabled,
  style,
  colors: initColors,
  onChange,
  ...rest
}: RadioButtonProps) => {
  const colors = { ...defaultColors, ...initColors };
  return (
    <Wrapper style={style}>
      <StyledRadio
        id={id}
        type="radio"
        colors={colors}
        style={{ opacity: disabled ? 0.5 : 1 }}
        disabled={disabled}
        onChange={onChange}
        {...rest}
      />
      <Label
        style={{ ...label.style, opacity: disabled ? 0.5 : 1 }}
        {...label}
        for={id}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;

  &:disabled {
    cursor: default;
  }
`;

const StyledRadio = styled.input<{ colors?: RadioButtonColors }>`
  appearance: none;
  margin: 0;
  width: 20px;
  height: 20px;
  border: 2px solid ${(props) => props.colors?.border};
  border-radius: 50%;
  transition: all 0.1s ease-in-out;
  &::after {
    content: "";
    display: block;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    margin: 2px;
  }

  &:checked::after {
    background-color: ${(props) => props.colors?.checked};
  }
  &:hover::after {
    background-color: ${(props) => props.colors?.checked};
  }
  &:focus {
    outline: 2px solid ${(props) => props.colors?.focus};
  }

  &:disabled {
    cursor: default;
  }
`;
