import { useNavigate } from "react-router-dom";

export interface NavigatorFunction {
  back: () => void;
  to: (to: string) => void;
}

export const useNavigator = (): NavigatorFunction => {
  const navigate = useNavigate();

  function back() {
    // eslint-disable-next-line no-magic-numbers
    navigate(-1);
  }

  function to(to: string) {
    navigate(to);
  }

  return { back, to };
};
