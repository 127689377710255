import { TypographyVariant } from "@hark-tech/components";
import styled from "styled-components";
import { Icon, IconType, Typography } from "../../components";

export const AddNewLocationButton = ({
  onClick,
}: {
  onClick: () => void;
}): JSX.Element => {
  return (
    <Circle onClick={onClick}>
      <LocationAnimation />
      <Typography
        variant={TypographyVariant.HEADER}
        emphasized
        text="Add new location"
      />
      <Icon type={IconType.PLUS} size={16} />
    </Circle>
  );
};

const Circle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 1px solid ${(props) => props.theme.colors.text["500"]};
`;

const LocationAnimation = (): JSX.Element => {
  return (
    <svg
      width="123"
      height="64"
      viewBox="0 0 123 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M58.7146 0L86.4273 16L44.8582 23.9999L31.0018 16L58.7146 0Z"
        fill="#B6ADA7"
      />
      <path
        d="M58.7114 47.9995V63.9993L86.4238 47.9996L86.4238 16L58.7114 47.9995Z"
        fill="#CAC1B5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31 32V31.9998V16L44.8562 23.9998V39.9999L58.7124 47.9997V63.9996L44.8562 55.9997L31 47.9998V47.9996V32Z"
        fill="#F6F3EF"
      />
      <path
        d="M40.113 53.2788L35.4941 50.6121L35.4941 45.2787V39.9453L40.113 42.612L40.113 53.2788Z"
        fill="#CAC1B5"
      />
      <path
        d="M35.4941 29.3021V23.9688L40.113 26.6354V31.9688L35.4941 29.3021Z"
        fill="#CAC1B5"
      />
      <path
        d="M44.8535 39.9999V24L86.4234 16L44.8535 39.9999Z"
        fill="url(#paint0_linear_3511_12330)"
      />
      <path
        d="M86.4234 16L44.8535 39.9999L58.711 47.9999L86.4234 16Z"
        fill="#B6ADA7"
      />
      <path
        d="M112.472 17L122.943 47.2292L112.472 53.275L102 47.2292L112.472 17Z"
        fill="#3CD39D"
      />
      <path
        d="M112.471 17L122.942 47.2292L112.471 53.275L112.471 17Z"
        fill="#19C587"
      />
      <path
        d="M7.5 49.2773L15 27.6267L7.5 23.2965L0 27.6267L7.5 49.2773Z"
        fill="#FFABAB"
      />
      <path
        d="M7.49902 49.2773L14.999 27.6267L7.49902 23.2965L7.49902 49.2773Z"
        fill="#EA8F8F"
      />
      <ellipse
        cx="7.49877"
        cy="26.4988"
        rx="7.49877"
        ry="7.4988"
        fill="#FFABAB"
      />
      <path
        d="M7.49877 19C8.48352 19 9.45863 19.194 10.3684 19.5708C11.2782 19.9477 12.1049 20.5 12.8012 21.1963C13.4975 21.8927 14.0499 22.7193 14.4267 23.6291C14.8036 24.5389 14.9975 25.514 14.9975 26.4988C14.9975 27.4836 14.8036 28.4587 14.4267 29.3685C14.0499 30.2783 13.4975 31.1049 12.8012 31.8013C12.1049 32.4976 11.2782 33.0499 10.3684 33.4268C9.45862 33.8036 8.48351 33.9976 7.49875 33.9976L7.49877 26.4988V19Z"
        fill="#EA8F8F"
      />
      <ellipse
        cx="7.49875"
        cy="26.4959"
        rx="1.87473"
        ry="1.87483"
        fill="#F4F4F4"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3511_12330"
          x1="44.7485"
          y1="39.9999"
          x2="86.3398"
          y2="15.7282"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F0EAE1" />
          <stop offset="0.65625" stopColor="#CAC1B5" />
        </linearGradient>
      </defs>
    </svg>
  );
};
