import { useMutation } from "@apollo/client";
import { useNavigator } from "@hark-tech/hooks";
import { useState } from "react";
import {
  LatLng,
  SetCoordinatesForLocationDocument,
  SetNameForLocationDocument,
} from "../../generated/graphql/graphql";
import { RouteMap } from "../Routes";
import { useLocation } from "../location-provider/LocationProvider";
import { LocationNameAndCoordinatesForm } from "./LocationNameAndCoordinatesForm";

export const UpdateLocationNameAndCoordinates = (): JSX.Element => {
  const navigate = useNavigator();
  const { location, refetch } = useLocation();

  const [latLng, setLatLng] = useState<LatLng>({
    latitude: location.coordinates.latitude,
    longitude: location.coordinates.longitude,
  });
  const [name, setName] = useState<string>(location.name);

  const [updateName, { loading, error }] = useMutation(
    SetNameForLocationDocument,
    {
      variables: {
        input: {
          locationId: location.id,
          name,
        },
      },
    }
  );

  const [
    updateCoordinates,
    { loading: coordinatesLoading, error: coordinatesError },
  ] = useMutation(SetCoordinatesForLocationDocument, {
    variables: {
      input: {
        coordinates: latLng as LatLng,
        locationId: location.id,
      },
    },
  });

  const onSave = () => {
    updateName();
    updateCoordinates();
    refetch();
    navigate.to(RouteMap.location.update.deviceId);
  };

  return (
    <LocationNameAndCoordinatesForm
      header="Update location"
      latLng={latLng}
      setLatLng={setLatLng}
      name={name}
      setName={setName}
      onBackClick={() => navigate.back()}
      onSave={onSave}
      disabled={name === "" || !latLng}
      error={error?.message || coordinatesError?.message}
      loading={loading || coordinatesLoading}
    />
  );
};
