import { useNavigator } from "@hark-tech/hooks";
import { RouteMap } from "../Routes";
import { AddDevice } from "../location-provider/AddDevice";
import { useLocation } from "../location-provider/LocationProvider";

export const UpdateLocationDeviceId = (): JSX.Element => {
  const navigate = useNavigator();
  const { location, refetch } = useLocation();

  const onSave = () => {
    refetch();
    navigate.to(RouteMap.location.update.limit);
  };

  return (
    <AddDevice
      locationId={location.id}
      deviceId={location.deviceId ?? undefined}
      onFinish={() => onSave()}
      onBackClick={() => navigate.back()}
    />
  );
};
