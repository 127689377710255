import { Routes as ReactRoutes, Route } from "react-router-dom";
import { Vendor } from "../generated/graphql/graphql";
import {
  UpdateLocationDeviceId,
  UpdateLocationHourlyConsumptionLimit,
  UpdateLocationNameAndCoordinates,
} from "./location";
import {
  AddConnection,
  DeleteConnection,
  Description,
  Overview,
  UpdateLocationConnectionsForVendor,
} from "./smart-control";

export const Routes = (): JSX.Element => {
  return (
    <ReactRoutes>
      <Route path={RouteMap.base} element={<Overview />} />

      <Route
        path={RouteMap.location.update.basePath}
        element={<UpdateLocationNameAndCoordinates />}
      />

      <Route
        path={RouteMap.location.update.deviceId}
        element={<UpdateLocationDeviceId />}
      />

      <Route
        path={RouteMap.location.update.limit}
        element={<UpdateLocationHourlyConsumptionLimit />}
      />

      <Route path={RouteMap.connections.add} element={<AddConnection />} />

      <Route
        path={RouteMap.connections.delete}
        element={<DeleteConnection />}
      />

      <Route
        path={RouteMap.connections.describe.basePath}
        element={<Description />}
      />

      <Route
        path={RouteMap.connections.update.basePath}
        element={<UpdateLocationConnectionsForVendor />}
      />
    </ReactRoutes>
  );
};

export const RouteMap = {
  base: "/",
  connections: {
    add: "/connections/add",
    basePath: "/connections",
    delete: "/connections/delete",
    describe: {
      basePath: "/connections/:vendor/describe",
      createPathForVendor: (vendor: Vendor): string =>
        `/connections/${vendor}/update`,
    },
    update: {
      basePath: "/connections/:vendor/update",
      createPathForVendor: (vendor: Vendor): string =>
        `/connections/${vendor}/update`,
    },
  },
  location: {
    update: {
      basePath: "/location/update",
      deviceId: "/location/update-deviceId",
      limit: "/location/update-limit",
    },
  },
};
