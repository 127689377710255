import {
  Typography as BaseTypography,
  TypographyProps,
} from "@hark-tech/components";
import { PropsWithChildren } from "react";
import { useTheme } from "styled-components";
import { TextColor, propsToTextColor } from "../util";

export const Typography = (
  props: PropsWithChildren<TypographyProps>
): JSX.Element => {
  const theme = useTheme();
  return (
    <BaseTypography
      style={{
        color: propsToTextColor({ $textColor: TextColor.PRIMARY, theme }),
      }}
      {...props}
    />
  );
};
