import { Controllable, Vendor } from "../generated/graphql/graphql";

export const sortControllablesByVendor = (controllables: Controllable[]) => {
  return groupBy(controllables, (controllable) => controllable.vendor);
};

export const getControllablesForVendor = (
  controllables: Controllable[],
  vendor: Vendor
) => {
  return sortControllablesByVendor(controllables).get(vendor);
};

function groupBy<K, V>(
  list: Array<V>,
  keyGetter: (input: V) => K
): Map<K, Array<V>> {
  const map = new Map<K, Array<V>>();
  list.forEach((item) => {
    const key = keyGetter(item);
    if (map.has(key)) {
      map.get(key)?.push(item);
    } else {
      map.set(key, [item]);
    }
  });
  return map;
}
