import { Label as BaseLabel } from "@hark-tech/components";
import { PropsWithChildren } from "react";
import { useTheme } from "styled-components";

interface LabelProps {
  text: string;
  for: string;
}


export const Label = (props: PropsWithChildren<LabelProps>): JSX.Element => {
  const theme = useTheme();
  return (
    <BaseLabel
      style={{ color: theme.colors.text[500], fontSize: "12px", fontWeight: 900, marginBottom: "8px", textTransform: "uppercase" }}
      {...props}
    />
  );
};

