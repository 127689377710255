/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  ClaimId: { input: any; output: any; }
  /** Date, given as an ISO 8601 string. Parsed as UTC if time offset is not given. */
  Date: { input: any; output: any; }
  DeviceId: { input: any; output: any; }
  /**
   * Global location number, used world wide to identify legal, physical and
   * functional locations. These are used as identifiers e.g. companies and power
   * meter ids.
   */
  Gln: { input: any; output: any; }
  IntermediaryId: { input: any; output: any; }
  JSON: { input: any; output: any; }
  MeterId: { input: any; output: any; }
  /** Nothing */
  Void: { input: any; output: any; }
};

export type Activation = {
  __typename?: 'Activation';
  status: ActivationStatus;
  updatedAt: Scalars['Date']['output'];
};

export type ActivationStatus =
  | 'ACTIVATED'
  | 'DEACTIVATED';

export type AddChargingLocationInput = {
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
  name: Scalars['String']['input'];
};

export type AddChargingLocationPayload = AddChargingLocationSuccessPayload | ChargingLocationAlreadyExistsPayload;

export type AddChargingLocationResponseType =
  | 'ALREADY_EXISTS'
  | 'SUCCESS';

export type AddChargingLocationSuccessPayload = {
  __typename?: 'AddChargingLocationSuccessPayload';
  chargingLocation: ChargingLocation;
  type: AddChargingLocationResponseType;
};

export type AddChargingPermissionInput = {
  userIdentifier: UserIdentifierInput;
};

export type AddChargingPermissionPayload = {
  __typename?: 'AddChargingPermissionPayload';
  modifiedPermission: Permission;
  success: Scalars['Boolean']['output'];
  user: UserInfo;
};

export type AddControllableForLocationInput = {
  controllableId: Scalars['String']['input'];
  locationId: Scalars['String']['input'];
};

export type AddControllableForLocationPayload = {
  __typename?: 'AddControllableForLocationPayload';
  location: Location;
};

export type AddDevicePermissionsInput = {
  deviceIdentifiers: Array<DeviceIdentifierInput>;
  userIdentifier: UserIdentifierInput;
};

export type AddDevicePermissionsPayload = {
  __typename?: 'AddDevicePermissionsPayload';
  modifiedPermissions: Array<Permission>;
  success: Scalars['Boolean']['output'];
  user: UserInfo;
};

export type AddEstimatedHourlyActiveEnergyLimitWarningAlertSettingInput = {
  deviceId: Scalars['String']['input'];
  limitInWattHours: Scalars['Int']['input'];
};

export type AddHourlyConsumptionLimitEstimationWarningAlertSettingInput = {
  deviceId: Scalars['String']['input'];
  limitInWatts: Scalars['Int']['input'];
};

export type AddIntermediaryApiKeyInput = {
  name: Scalars['String']['input'];
};

export type AddIntermediaryWebhookNotificationChannelToAlertSettingInput = {
  alertSettingId: Scalars['ID']['input'];
  callbackUrl: Scalars['String']['input'];
  intermediaryId?: InputMaybe<Scalars['String']['input']>;
  metadata?: InputMaybe<Scalars['JSON']['input']>;
};

export type AddIntermediaryWebhookNotificationChannelToAlertSettingPayload = {
  __typename?: 'AddIntermediaryWebhookNotificationChannelToAlertSettingPayload';
  notificationChannel: IntermediaryWebhookNotificationChannel;
};

export type AddLocationInput = {
  coordinates: LatLngInput;
  name: Scalars['String']['input'];
};

export type AddLocationPayload = {
  __typename?: 'AddLocationPayload';
  location: Location;
};

export type AddPriceWarningAlertSettingInput = {
  energyBiddingZone: EnergyBiddingZone;
};

export type AddPushNotificationChannelToAlertSettingInput = {
  alertSettingId: Scalars['ID']['input'];
};

export type AddPushNotificationChannelToAlertSettingPayload = {
  __typename?: 'AddPushNotificationChannelToAlertSettingPayload';
  notificationChannel: PushNotificationChannel;
};

export type AddUserInput = {
  userIdentifier: UserIdentifierInput;
};

export type AddUserPayload = {
  __typename?: 'AddUserPayload';
  success: Scalars['Boolean']['output'];
  user: UserInfo;
};

export type AddUserSessionInput = {
  userIdentifier: UserIdentifierInput;
};

export type AddUserSessionPayload = {
  __typename?: 'AddUserSessionPayload';
  session: SessionInfo;
  success: Scalars['Boolean']['output'];
  user: UserInfo;
};

export type AlertChannelType =
  | 'INTERMEDIARY_WEBHOOK'
  | 'PUSH_NOTIFICATION';

export type AlertSetting = EstimatedHourlyActiveEnergyLimitWarningAlertSetting | HourlyConsumptionLimitEstimationWarningAlertSetting | PriceWarningAlertSetting;

export type AlertSettingCreator = EndUserAlertSettingCreator | IntermediaryAlertSettingCreator;

export type AlertSettingCreatorType =
  | 'END_USER'
  | 'INTERMEDIARY';

export type AlertType =
  | 'ESTIMATED_HOURLY_ACTIVE_ENERGY_LIMIT_WARNING'
  | 'HOURLY_CONSUMPTION_LIMIT_ESTIMATION_WARNING'
  | 'PRICE_WARNING';

export type CancelOrderInput = {
  orderId: Scalars['String']['input'];
};

export type CapacityModelType =
  | 'FuseAndVoltageSize'
  | 'MonthlyPeaksAtDifferentDays'
  | 'MonthlyPeaksAtDifferentDaysLinearWithBase'
  | 'WeightedYearlyRollingPeaksInDifferentWeeks';

/** The capacity steps includes as range in Wh and a price for the capacity step */
export type CapacityStep = {
  __typename?: 'CapacityStep';
  capacityStepPrice: PriceSpecification;
  rangeInWh: EnergyRangeInWh;
};

export type ChargeCost = {
  __typename?: 'ChargeCost';
  cost: Scalars['Float']['output'];
  currency?: Maybe<Currency>;
  savings: Scalars['Float']['output'];
};

export type ChargingHistoryEntry = {
  __typename?: 'ChargingHistoryEntry';
  chargedEnergyInKwh: Scalars['Float']['output'];
  endTime: Scalars['Date']['output'];
  startTime: Scalars['Date']['output'];
};

export type ChargingLocation = {
  __typename?: 'ChargingLocation';
  id: Scalars['ID']['output'];
  latitude: Scalars['Float']['output'];
  longitude: Scalars['Float']['output'];
  name: Scalars['String']['output'];
};

export type ChargingLocationAlreadyExistsPayload = {
  __typename?: 'ChargingLocationAlreadyExistsPayload';
  type: AddChargingLocationResponseType;
};

export type ChargingSession = {
  __typename?: 'ChargingSession';
  chargeCost: ChargeCost;
  chargingLocationId: Scalars['String']['output'];
  energy: Array<Maybe<ChargingHistoryEntry>>;
  isSmartSession: Scalars['Boolean']['output'];
  sessionId: Scalars['String']['output'];
};

export type ChargingStatisticsEntry = {
  __typename?: 'ChargingStatisticsEntry';
  costSum: Scalars['Float']['output'];
  date: Scalars['String']['output'];
  estimatedSavings: Scalars['Float']['output'];
  kw: Range;
  kwhSum: Scalars['Float']['output'];
  nonSmartPrice: Range;
  price: Range;
  priceCurrency: Currency;
  smartSession: Scalars['Boolean']['output'];
};

export type ChargingStatisticsInput = {
  chargingLocationId?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  resolution?: InputMaybe<ChargingStatisticsResolution>;
  startDate: Scalars['Date']['input'];
  type?: InputMaybe<ChargingStatisticsType>;
  utcOffset?: InputMaybe<Scalars['Float']['input']>;
};

export type ChargingStatisticsPayload = {
  __typename?: 'ChargingStatisticsPayload';
  entries: Array<ChargingStatisticsEntry>;
};

export type ChargingStatisticsResolution =
  | 'DAY'
  | 'HALF_HOUR'
  | 'HOUR'
  | 'MONTH'
  | 'WEEK'
  | 'YEAR';

export type ChargingStatisticsType =
  | 'CHARGER'
  | 'HVAC'
  | 'VEHICLE';

export type CheckJedlixConnectionInput = {
  connectionId: Scalars['String']['input'];
};

export type CommonSignInInput = {
  password: Scalars['String']['input'];
  username: Scalars['String']['input'];
  vendor: CommonSignInVendor;
};

export type CommonSignInPayload = {
  __typename?: 'CommonSignInPayload';
  success: Scalars['Boolean']['output'];
};

export type CommonSignInVendor =
  | 'MILL';

export type ConfiguredIntegrationVendorDevice = ConfiguredIntegrationVendorVehicle;

export type ConfiguredIntegrationVendorInfo = {
  __typename?: 'ConfiguredIntegrationVendorInfo';
  devices: Array<ConfiguredIntegrationVendorDevice>;
  status: IntegrationVendorStatus;
  vendor: IntegrationVendor;
};

export type ConfiguredIntegrationVendorVehicle = {
  __typename?: 'ConfiguredIntegrationVendorVehicle';
  chargeState: ConfiguredIntegrationVendorVehicleChargeState;
  chargingLocationId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isReachable: Scalars['Boolean']['output'];
  isSmartChargingCapable: Scalars['Boolean']['output'];
  location: ConfiguredIntegrationVendorVehicleLocationState;
  model: Scalars['String']['output'];
  smartChargingPolicy?: Maybe<SmartChargingPolicy>;
  vendor: IntegrationVendor;
  yearOfProduction: Scalars['Int']['output'];
};

export type ConfiguredIntegrationVendorVehicleChargeState = {
  __typename?: 'ConfiguredIntegrationVendorVehicleChargeState';
  batteryCapacity: Scalars['Float']['output'];
  batteryLevel: Scalars['Int']['output'];
  chargeRate: Scalars['Float']['output'];
  chargeTimeRemaining: Scalars['Int']['output'];
  isCharging: Scalars['Boolean']['output'];
  isFullyCharged: Scalars['Boolean']['output'];
  isPluggedIn: Scalars['Boolean']['output'];
  range: Scalars['Float']['output'];
};

export type ConfiguredIntegrationVendorVehicleLocationState = GpsDisabledConfiguredIntegrationVendorVehicleLocationState | GpsNeverActivatedConfiguredIntegrationVendorVehicleLocationState | LocationAvailableConfiguredIntegrationVendorVehicleLocationState;

export type ConfiguredIntegrationVendorVehicleLocationStateType =
  | 'GPS_DISABLED'
  | 'GPS_NEVER_ACTIVATED'
  | 'LOCATION_AVAILABLE';

export type ConnectExternalUserInput = {
  userIdentifier: UserIdentifierInput;
};

export type ConnectExternalUserPayload = {
  __typename?: 'ConnectExternalUserPayload';
  /** Devices shipped to the user. If no devices have been shipped yet, the list is empty. Use the `ordersForUser` query to know the status of the user's orders. */
  devices: Array<DeviceInfo>;
  /** The sessions object contains an `accessToken` to be used by apps to run queries on behalf of the external user. This token will have permissions to get data for the devices shipped to the user. */
  session: SessionInfo;
  success: Scalars['Boolean']['output'];
  user: UserInfo;
};

export type ConsideringSmartChargingStatus = {
  __typename?: 'ConsideringSmartChargingStatus';
  consideration: SmartChargingConsideration;
  externalStart: Scalars['Boolean']['output'];
  type: SmartChargingStatusType;
};

/** Has the same price for all times of the day, week and year */
export type ConstantPrice = {
  __typename?: 'ConstantPrice';
  pricesAndTaxes: PricesAndTaxes;
  rushPricing?: Maybe<Array<Maybe<RushPricingPeriod>>>;
  type: EnergyModelType;
};

export type Controllable = {
  __typename?: 'Controllable';
  id: Scalars['String']['output'];
  intermediaryId?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  ownerId: Scalars['String']['output'];
  type: ControllableType;
  vendor: Vendor;
};

export type ControllableType =
  | 'ROOM';

export type ControllablesForUserPayload = {
  __typename?: 'ControllablesForUserPayload';
  controllables: Array<Controllable>;
};

export type CountryCode =
  | 'NO';

export type CreateJedlixChargingLocationInput = {
  address: JedlixAddressInput;
  coordinates: LatLngInput;
};

export type CreateJedlixUserInput = {
  defaultCountry: CountryCode;
  locale: Scalars['String']['input'];
};

export type CreationInfo = {
  __typename?: 'CreationInfo';
  at: Scalars['Date']['output'];
  by: UserInfo;
};

export type Currency =
  | 'NOK';

export type CurrentIntermediaryPayload = {
  __typename?: 'CurrentIntermediaryPayload';
  alertSettings?: Maybe<Array<AlertSetting>>;
};


export type CurrentIntermediaryPayloadAlertSettingsArgs = {
  input?: InputMaybe<IntermediaryAlertSettingsInput>;
};

export type CurrentRangePrice = {
  __typename?: 'CurrentRangePrice';
  /** From, exclusive */
  fromCurrent: Scalars['Int']['output'];
  /** To, inclusive */
  toCurrent: Scalars['Int']['output'];
  /** Price for this current range */
  yearlyPrice: PriceSpecification;
};

export type CustomError = {
  message: Scalars['String']['output'];
  type: ErrorType;
};

export type DateRange = {
  __typename?: 'DateRange';
  /** Day of month and month as a number, including this date */
  from: DayAndMonth;
  /** Day of month and month as a number, excluding this date */
  until: DayAndMonth;
};

export type DayAndMonth = {
  __typename?: 'DayAndMonth';
  day: Scalars['Int']['output'];
  month: Month;
};

/**
 * There may be different prices for day and night, maybe in combination with
 * seasonal (summer/winter) prices. Usually the day is from 6 to 22, but not
 * always.
 */
export type DayAndNightPrices = {
  __typename?: 'DayAndNightPrices';
  day: PricesAndTaxesForPartOfDay;
  night: PricesAndTaxesForPartOfDay;
};

export type DeleteAlertSettingInput = {
  id: Scalars['ID']['input'];
};

export type DeleteAlertSettingPayload = {
  __typename?: 'DeleteAlertSettingPayload';
  success: Scalars['Boolean']['output'];
};

export type DeleteChargingLocationInput = {
  chargingLocationId: Scalars['String']['input'];
};

export type DeleteChargingLocationPayload = {
  __typename?: 'DeleteChargingLocationPayload';
  success: Scalars['Boolean']['output'];
};

export type DeleteJedlixChargingLocationInput = {
  chargingLocationId: Scalars['String']['input'];
};

export type DeleteJedlixChargingLocationPayload = {
  __typename?: 'DeleteJedlixChargingLocationPayload';
  response?: Maybe<Scalars['Void']['output']>;
};

export type DeleteJedlixUserPayload = {
  __typename?: 'DeleteJedlixUserPayload';
  response?: Maybe<Scalars['Void']['output']>;
};

export type DeleteJedlixVehicleInput = {
  vehicleId: Scalars['String']['input'];
};

export type DeleteJedlixVehiclePayload = {
  __typename?: 'DeleteJedlixVehiclePayload';
  response?: Maybe<Scalars['Void']['output']>;
};

export type DeleteMeInput = {
  accessToken: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type DeleteMePayload = {
  __typename?: 'DeleteMePayload';
  success: Scalars['Boolean']['output'];
  user: UserInfo;
};

export type DeliveryChargeCapacityModel = FuseAndVoltageSize | MonthlyPeaksAtDifferentDays | MonthlyPeaksAtDifferentDaysLinearWithBase | WeightedYearlyRollingPeaksInDifferentWeeks;

export type DeliveryChargeEnergyModel = ConstantPrice | DifferentPricesDayAndNight | DifferentPricesSeasonal | DifferentPricesSeasonalDayAndNight | SelectableEnergyModel;

/** Energy taxes (electrical power tax and energy fund tax) */
export type DeliveryChargeEnergyTaxes = {
  __typename?: 'DeliveryChargeEnergyTaxes';
  /** Electrical power tax (Avgift på elektrisk kraft, also called "forbruksavgift") */
  electricalPowerTax: PriceSpecification;
  /** Tax to the energy fund (Enova-avgift) */
  energyFund: PriceSpecification;
};

export type DeliveryChargeModel = {
  __typename?: 'DeliveryChargeModel';
  capacityModel: DeliveryChargeCapacityModel;
  energyModel: DeliveryChargeEnergyModel;
  gln: Scalars['Gln']['output'];
  id: DeliveryChargeModelId;
  taxesApplicable: DeliveryChargeTaxesApplicable;
  timezone: Timezone;
  validFrom: Scalars['Date']['output'];
};

export type DeliveryChargeModelId =
  | 'AGDER_ENERGI_NETT'
  | 'ALUT'
  | 'ANDOY_NETT'
  | 'ARVA'
  | 'ASKER_NETT'
  | 'AUSTEVOLL_KRAFTLAG'
  | 'BARENTS_NETT'
  | 'BINDAL_KRAFTLAG'
  | 'BKK_NETT'
  | 'BOMLO_KRAFTNETT'
  | 'BREHEIM_NETT'
  | 'DE_NETT'
  | 'ELINETT'
  | 'ELMEA'
  | 'ELVENETT'
  | 'ELVERKET_HOLAND'
  | 'ELVIA'
  | 'ENIDA'
  | 'EVERKET'
  | 'FAGNE'
  | 'FJELLNETT'
  | 'FOIE'
  | 'FORE'
  | 'GLITRE_ENERGI_NETT'
  | 'GLITRE_NETT'
  | 'GRIUG'
  | 'HALLINGDAL_KRAFTNETT'
  | 'HALOGALAND_KRAFT_NETT'
  | 'HEMSIL'
  | 'INDRE_HORDALAND_KRAFTNETT'
  | 'ISALTEN_NETT'
  | 'JAREN_EVERK'
  | 'KE_NETT'
  | 'KLIVE'
  | 'KVAM_ENERGI_NETT'
  | 'KYSTNETT'
  | 'LEDE'
  | 'LEGA_NETT'
  | 'LINEA'
  | 'LINJA'
  | 'LNETT'
  | 'LUCERNA'
  | 'LUOSTEJOK_NETT'
  | 'LYSNA'
  | 'MELLOM'
  | 'MELOY_NETT'
  | 'MIDTNETT'
  | 'MODALEN_KRAFTLAG'
  | 'MORENETT'
  | 'NEAS'
  | 'NETTINORD'
  | 'NETTSELSKAPET'
  | 'NORDLANDSNETT'
  | 'NORDVESTNETT'
  | 'NOREFJELL_NETT'
  | 'NORGESNETT'
  | 'ODDA_ENERGI_NETT'
  | 'RAKKESTAD_ENERGI'
  | 'RAUMA_ENERGI'
  | 'RK_NETT'
  | 'ROLLAG_ELVERK_NETT'
  | 'ROMSDALSNETT'
  | 'ROROS_EVERK_NETT'
  | 'SANDOY_ENERGI'
  | 'SKIAKER_NETT'
  | 'SKJAK_ENERGI_NETT'
  | 'SOR_AURDAL_ENERGI_NETT'
  | 'STANGE_ENERGI_NETT'
  | 'STANNUM'
  | 'STRAUMEN_NETT'
  | 'STRAUMNETT'
  | 'SUNETT'
  | 'SYGNIR'
  | 'S_NETT'
  | 'TELEMARK_NETT'
  | 'TENSIO_TN'
  | 'TENSIO_TS'
  | 'TINDRA_NETT'
  | 'TROLLFJORD_NETT'
  | 'TROMS_KRAFT_NETT'
  | 'UVDAL_KRAFTFORSYNING'
  | 'VANG_ENERGI'
  | 'VESTALL'
  | 'VESTMAR_NETT'
  | 'VESTTELEMARK_KRAFTLAG'
  | 'VEVIG'
  | 'VISSI'
  | 'VONETT';

/** Query delivery charge models using `gln` or our `id` */
export type DeliveryChargeModelInput = {
  date?: InputMaybe<Scalars['Date']['input']>;
  gln?: InputMaybe<Scalars['Gln']['input']>;
  id?: InputMaybe<DeliveryChargeModelId>;
};

export type DeliveryChargeTaxesApplicable = {
  __typename?: 'DeliveryChargeTaxesApplicable';
  electricalPowerTax: Scalars['Boolean']['output'];
  vat: Scalars['Boolean']['output'];
};

export type DeviceFilter = {
  deviceIds?: InputMaybe<Array<Scalars['String']['input']>>;
  recentMeterIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type DeviceIdentifierInput = {
  deviceId?: InputMaybe<Scalars['DeviceId']['input']>;
  meterId?: InputMaybe<Scalars['MeterId']['input']>;
};

export type DeviceInfo = {
  __typename?: 'DeviceInfo';
  activation?: Maybe<Activation>;
  claimId: Scalars['String']['output'];
  claimedAt?: Maybe<Scalars['String']['output']>;
  claimedBy?: Maybe<Scalars['String']['output']>;
  deviceId: Scalars['String']['output'];
  deviceName?: Maybe<Scalars['String']['output']>;
  diagnostic?: Maybe<DiagnosticInfo>;
  intermediaryId?: Maybe<Scalars['String']['output']>;
  metadata?: Maybe<DeviceMetadata>;
  ownedBy?: Maybe<OwnedByUserInfo>;
  permissions: Array<DevicePermission>;
  preferredEnergyBiddingZone?: Maybe<EnergyBiddingZone>;
  recentMeter?: Maybe<RecentMeter>;
  recentMeterId?: Maybe<Scalars['MeterId']['output']>;
  returnInfo?: Maybe<ReturnInfo>;
  shippedAt?: Maybe<Scalars['String']['output']>;
  shippedTo?: Maybe<ShippedToUserInfo>;
  subscription?: Maybe<SubscriptionBilling>;
  type: Scalars['String']['output'];
  webhookCallbackUrl?: Maybe<Scalars['String']['output']>;
};

export type DeviceInput = {
  id: Scalars['String']['input'];
  metadata: DeviceMetadataInput;
  type: DeviceType;
};

export type DeviceMetadata = {
  __typename?: 'DeviceMetadata';
  chip?: Maybe<Scalars['String']['output']>;
  espIdfVersionFactoryPartition?: Maybe<Scalars['String']['output']>;
  firmwareTheme?: Maybe<Scalars['String']['output']>;
  firmwareVersionFactoryPartition?: Maybe<Scalars['String']['output']>;
  iccid?: Maybe<Scalars['String']['output']>;
  imei?: Maybe<Scalars['String']['output']>;
  memoryPartitioning?: Maybe<Scalars['String']['output']>;
  modemFirmwareVersion?: Maybe<Scalars['String']['output']>;
  productBatch?: Maybe<Scalars['String']['output']>;
};

export type DeviceMetadataInput = {
  chip: Scalars['String']['input'];
  espIdfVersionFactoryPartition: Scalars['String']['input'];
  firmwareTheme: Scalars['String']['input'];
  firmwareVersionFactoryPartition: Scalars['String']['input'];
  iccid?: InputMaybe<Scalars['String']['input']>;
  imei?: InputMaybe<Scalars['String']['input']>;
  memoryPartitioning: Scalars['String']['input'];
  modemFirmwareVersion?: InputMaybe<Scalars['String']['input']>;
  productBatch: Scalars['String']['input'];
};

/**
 * Input object for updating ownership information
 *
 * Fields will be replaced and missing fields will be removed
 */
export type DeviceOwnerInput = {
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  externalUserId?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  postalAddress?: InputMaybe<OptionalFieldsPostalAddressInput>;
};

export type DevicePermission = {
  __typename?: 'DevicePermission';
  permittedEmail: Scalars['String']['output'];
  permittedUserId: Scalars['String']['output'];
};

export type DeviceQueryIdentifier = {
  claimId?: InputMaybe<Scalars['String']['input']>;
  deviceId?: InputMaybe<Scalars['String']['input']>;
  recentMeterId?: InputMaybe<Scalars['String']['input']>;
};

export type DeviceQueryInput = {
  identifier: DeviceQueryIdentifier;
};

export type DeviceQueryPayload = {
  __typename?: 'DeviceQueryPayload';
  device: DeviceInfo;
};

export type DeviceStats = {
  __typename?: 'DeviceStats';
  devicesActivated: Scalars['Int']['output'];
  devicesActivationReady: Scalars['Int']['output'];
  devicesAtCustomer: Scalars['Int']['output'];
  devicesDeactivated: Scalars['Int']['output'];
  returnedDevices: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type DeviceType =
  | 'HAN_LTEM'
  | 'HAN_WIFI';

/** Diagnostic about network state and reboots */
export type DiagnosticInfo = {
  __typename?: 'DiagnosticInfo';
  firmwareVersion?: Maybe<Scalars['Int']['output']>;
  lastMessageAt?: Maybe<Scalars['Date']['output']>;
  lastPingAt?: Maybe<Scalars['Date']['output']>;
  publishInterval?: Maybe<Scalars['Float']['output']>;
  reboots?: Maybe<Scalars['Int']['output']>;
  signalStrength?: Maybe<Scalars['Int']['output']>;
};

/**
 * Has different prices for day and night, may also have different prices for
 * weekends and holidays
 */
export type DifferentPricesDayAndNight = {
  __typename?: 'DifferentPricesDayAndNight';
  holidayPricesFollowNightPrices: Scalars['Boolean']['output'];
  prices: DayAndNightPrices;
  type: EnergyModelType;
  weekendPricesFollowNightPrices: Scalars['Boolean']['output'];
};

/** Has different prices for summer and winter */
export type DifferentPricesSeasonal = {
  __typename?: 'DifferentPricesSeasonal';
  seasons: SeasonalPricesWithoutDayAndNight;
  type: EnergyModelType;
};

/**
 * Has different prices for summer and winter, day and night, may also have
 * different prices for weekends and holidays
 */
export type DifferentPricesSeasonalDayAndNight = {
  __typename?: 'DifferentPricesSeasonalDayAndNight';
  holidayPricesFollowNightPrices: Scalars['Boolean']['output'];
  seasons: SeasonalPrices;
  type: EnergyModelType;
  weekendPricesFollowNightPrices: Scalars['Boolean']['output'];
};

export type DisabledSmartChargingStatus = {
  __typename?: 'DisabledSmartChargingStatus';
  externalStart: Scalars['Boolean']['output'];
  type: SmartChargingStatusType;
};

export type DisconnectVendorInput = {
  vendor: IntegrationVendor;
};

export type DisconnectVendorPayload = {
  __typename?: 'DisconnectVendorPayload';
  success: Scalars['Boolean']['output'];
};

export type EmailActionInput = {
  email: Scalars['String']['input'];
  redirectUri?: InputMaybe<Scalars['String']['input']>;
};

export type EmailIdentity = IdentityBase & {
  __typename?: 'EmailIdentity';
  email: Scalars['String']['output'];
  type: IdentityType;
};

export type EmailIdentityInput = {
  email: Scalars['String']['input'];
};

export type EndUserAlertSettingCreator = {
  __typename?: 'EndUserAlertSettingCreator';
  type: AlertSettingCreatorType;
  userId: Scalars['ID']['output'];
};

/** Energy bidding zones, as reported by the _European Network of Transmission System Operators for Electricity_ (ENTSO-E). */
export type EnergyBiddingZone =
  /** Norway: south east */
  | 'NO1'
  /** Norway: south */
  | 'NO2'
  /** Norway: mid */
  | 'NO3'
  /** Norway: north */
  | 'NO4'
  /** Norway: west */
  | 'NO5'
  | 'UNRECOGNIZED';

export type EnergyModelType =
  | 'ConstantPrice'
  | 'DifferentPricesDayAndNight'
  | 'DifferentPricesSeasonal'
  | 'DifferentPricesSeasonalDayAndNight'
  | 'SelectableEnergyModel';

export type EnergyPriceOfHour = {
  __typename?: 'EnergyPriceOfHour';
  from?: Maybe<Scalars['Date']['output']>;
  priceSpecification?: Maybe<PriceSpecification>;
  to?: Maybe<Scalars['Date']['output']>;
};

/**
 * Energy range is inclusive the from and to fields. A normal range is from 0 to
 * 1999 Wh. The granularity of our data is in Wh.
 */
export type EnergyRangeInWh = {
  __typename?: 'EnergyRangeInWh';
  /** From, inclusive */
  from: Scalars['Int']['output'];
  /** To, inclusive */
  to: Scalars['Int']['output'];
};

export type Error = UserNotAuthenticatedError | UserNotPermittedError;

export type ErrorType =
  | 'UserNotAuthenticatedError'
  | 'UserNotPermittedError';

export type EstimatedHourlyActiveEnergyLimitWarningAlertSetting = {
  __typename?: 'EstimatedHourlyActiveEnergyLimitWarningAlertSetting';
  alertType: AlertType;
  createdBy: AlertSettingCreator;
  deviceId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  limitInWattHours: Scalars['Int']['output'];
  notificationChannels: Array<NotificationChannel>;
};

export type ExternalUserIdentifier = {
  __typename?: 'ExternalUserIdentifier';
  externalId: Scalars['String']['output'];
};

export type ExternalUserIdentifierInput = {
  externalId: Scalars['String']['input'];
};

export type ExternalUserIdentity = IdentityBase & {
  __typename?: 'ExternalUserIdentity';
  externalId: Scalars['String']['output'];
  intermediaryId: Scalars['String']['output'];
  type: IdentityType;
};

export type FullyChargedSmartChargingStatus = {
  __typename?: 'FullyChargedSmartChargingStatus';
  externalStart: Scalars['Boolean']['output'];
  type: SmartChargingStatusType;
};

/**
 * Capacity model where grid voltage and/or fuse size make the capacity steps.
 *
 * Capacity model used by Alut, NettiNord, SuNett.
 */
export type FuseAndVoltageSize = {
  __typename?: 'FuseAndVoltageSize';
  /** Prices for one phase networks */
  onePhasePrices: PhasePrices;
  /** Prices for three phase networks */
  threePhasePrices: PhasePrices;
  type: CapacityModelType;
};

export type GpsDisabledConfiguredIntegrationVendorVehicleLocationState = {
  __typename?: 'GpsDisabledConfiguredIntegrationVendorVehicleLocationState';
  type: ConfiguredIntegrationVendorVehicleLocationStateType;
};

export type GpsNeverActivatedConfiguredIntegrationVendorVehicleLocationState = {
  __typename?: 'GpsNeverActivatedConfiguredIntegrationVendorVehicleLocationState';
  type: ConfiguredIntegrationVendorVehicleLocationStateType;
};

export type GrantDeviceAccessInput = {
  deviceId: Scalars['String']['input'];
  permittedIdentity: EmailIdentityInput;
};

export type HourRange = {
  __typename?: 'HourRange';
  /** 0-23, including start */
  from: Scalars['Int']['output'];
  /** 0-23, excluding end */
  until: Scalars['Int']['output'];
};

export type HourlyConsumptionLimitEstimationWarningAlertSetting = {
  __typename?: 'HourlyConsumptionLimitEstimationWarningAlertSetting';
  alertType: AlertType;
  createdBy: AlertSettingCreator;
  deviceId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  limitInWatts: Scalars['Int']['output'];
  notificationChannels: Array<NotificationChannel>;
};

export type Identity = EmailIdentity | ExternalUserIdentity | IntermediaryApiKeyIdentity | InternalIdentity;

export type IdentityBase = {
  type: IdentityType;
};

export type IdentityType =
  | 'EmailIdentity'
  | 'ExternalUserIdentity'
  | 'IntermediaryApiKeyIdentity'
  | 'InternalIdentity';

export type IntegrationAuthorizationUrlInput = {
  vendor: IntegrationVendor;
};

export type IntegrationAuthorizationUrlResponse = {
  __typename?: 'IntegrationAuthorizationUrlResponse';
  authorizationUrl: Scalars['String']['output'];
};

export type IntegrationSessionInput = {
  integration: IntegrationType;
  redirectUrl: Scalars['String']['input'];
};

export type IntegrationSessionPayload = {
  __typename?: 'IntegrationSessionPayload';
  url: Scalars['String']['output'];
};

export type IntegrationType =
  | 'MILL'
  | 'ZAPTEC';

export type IntegrationVendor =
  | 'AUDI'
  | 'BMW'
  | 'CUPRA'
  | 'FORD'
  | 'JAGUAR'
  | 'MINI'
  | 'PORSCHE'
  | 'RENAULT'
  | 'SEAT'
  | 'SKODA'
  | 'TESLA'
  | 'VOLKSWAGEN'
  | 'VOLVO';

export type IntegrationVendorInfo = ConfiguredIntegrationVendorInfo | NotConfiguredIntegrationVendorInfo;

export type IntegrationVendorStatus =
  | 'CONFIGURED'
  | 'NOT_CONFIGURED';

export type IntermediaryAlertSettingCreator = {
  __typename?: 'IntermediaryAlertSettingCreator';
  intermediaryId: Scalars['String']['output'];
  type: AlertSettingCreatorType;
};

export type IntermediaryAlertSettingsFilter = {
  deviceIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type IntermediaryAlertSettingsInput = {
  filter?: InputMaybe<IntermediaryAlertSettingsFilter>;
};

/** Intermediary api keys are authorized at the intermediate level, to add or remove users on behalf of the intermediary. */
export type IntermediaryApiKey = {
  __typename?: 'IntermediaryApiKey';
  apiKey?: Maybe<Scalars['String']['output']>;
  created: CreationInfo;
  id: Scalars['ID']['output'];
  intermediaryId: Scalars['String']['output'];
  name: Scalars['String']['output'];
  secret?: Maybe<Scalars['String']['output']>;
  startOfSecret: Scalars['String']['output'];
  /** @deprecated Use `apiKey` */
  token?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['Date']['output'];
};

export type IntermediaryApiKeyIdentity = IdentityBase & {
  __typename?: 'IntermediaryApiKeyIdentity';
  apiKeyHash: Scalars['String']['output'];
  type: IdentityType;
};

export type IntermediaryApiKeyPayload = {
  __typename?: 'IntermediaryApiKeyPayload';
  intermediaryApiKey: IntermediaryApiKey;
};

export type IntermediaryApiKeys = {
  __typename?: 'IntermediaryApiKeys';
  keys: Array<IntermediaryApiKey>;
};

export type IntermediaryApiKeysResult = IntermediaryApiKeys;

/** An intermediary is the entity or organization that has support responsibility for the devices. */
export type IntermediaryInfo = {
  __typename?: 'IntermediaryInfo';
  apiKeys?: Maybe<IntermediaryApiKeysResult>;
  deviceStats?: Maybe<DeviceStats>;
  devices?: Maybe<Array<DeviceInfo>>;
  id: Scalars['ID']['output'];
  permissionScopes?: Maybe<Array<Scalars['String']['output']>>;
};


/** An intermediary is the entity or organization that has support responsibility for the devices. */
export type IntermediaryInfoDevicesArgs = {
  search?: InputMaybe<Search>;
};

export type IntermediaryUserScope =
  | 'READ_BUFFERED_MEASUREMENTS'
  | 'READ_DEVICE_INFO'
  | 'READ_HOURLY_REPORTS'
  | 'READ_MEASUREMENT_STREAM';

export type IntermediaryUserTokenPayload = {
  __typename?: 'IntermediaryUserTokenPayload';
  accessToken: Scalars['String']['output'];
  user: UserInfo;
};

export type IntermediaryWebhookNotificationChannel = {
  __typename?: 'IntermediaryWebhookNotificationChannel';
  callbackUrl: Scalars['String']['output'];
  metadata?: Maybe<Scalars['JSON']['output']>;
  type: NotificationChannelType;
};

export type IntermediaryWebhookSetting = {
  __typename?: 'IntermediaryWebhookSetting';
  id: Scalars['String']['output'];
};

export type InternalIdentity = IdentityBase & {
  __typename?: 'InternalIdentity';
  id: Scalars['String']['output'];
  type: IdentityType;
};

export type Interval =
  | 'DAILY'
  | 'HOURLY'
  | 'MONTHLY';

export type JedlixActiveNotificationsPayload = {
  __typename?: 'JedlixActiveNotificationsPayload';
  notifications: Array<Maybe<JedlixNotification>>;
};

export type JedlixAddress = {
  __typename?: 'JedlixAddress';
  city?: Maybe<Scalars['String']['output']>;
  country: CountryCode;
  houseNumber?: Maybe<Scalars['String']['output']>;
  postalCode: Scalars['String']['output'];
  priceArea?: Maybe<EnergyBiddingZone>;
  region?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
};

export type JedlixAddressInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  country: CountryCode;
  houseNumber?: InputMaybe<Scalars['String']['input']>;
  postalCode: Scalars['String']['input'];
  region?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
};

export type JedlixBrand =
  | 'ABARTH'
  | 'AIWAYS'
  | 'AUDI'
  | 'BENTLEY'
  | 'BMW'
  | 'BYD'
  | 'BYTON'
  | 'CADILLAC'
  | 'CHEVROLET'
  | 'CITROEN'
  | 'CUPRA'
  | 'DACIA'
  | 'DR_AUTOMOBILE'
  | 'DS'
  | 'FIAT'
  | 'FISKER'
  | 'FORD'
  | 'GENESIS'
  | 'HONDA'
  | 'HONGQI'
  | 'HYUNDAI'
  | 'JAC'
  | 'JAGUAR'
  | 'JEEP'
  | 'KARMA'
  | 'KIA'
  | 'LAND_ROVER'
  | 'LEVC'
  | 'LEXUS'
  | 'LIGHTYEAR'
  | 'LOTUS'
  | 'LUCID'
  | 'LYNC_AND_CO'
  | 'MASERATI'
  | 'MAZDA'
  | 'MERCEDES'
  | 'MG'
  | 'MINI'
  | 'MITSUBISHI'
  | 'NIO'
  | 'NISSAN'
  | 'OPEL'
  | 'PEUGEOT'
  | 'POLESTAR'
  | 'PORSCHE'
  | 'RENAULT'
  | 'ROLLS_ROYCE'
  | 'SEAT'
  | 'SERES'
  | 'SKODA'
  | 'SMART'
  | 'SONO'
  | 'SSANG_YONG'
  | 'SUBARU'
  | 'SUZUKI'
  | 'TESLA'
  | 'TEST'
  | 'TOYOTA'
  | 'VINFAST'
  | 'VOLKSWAGEN'
  | 'VOLVO'
  | 'XPENG';

export type JedlixChargeState = {
  __typename?: 'JedlixChargeState';
  batteryLevel: Scalars['Float']['output'];
  chargePower: Scalars['Float']['output'];
  chargingLocationId: Scalars['String']['output'];
  chargingStatus: VehicleChargingStatus;
  range: Scalars['Float']['output'];
  updatedAt: Scalars['Date']['output'];
};

export type JedlixChargingHistoryInput = {
  endTimeFrom: Scalars['Date']['input'];
  interval: Scalars['Int']['input'];
  startTimeTo: Scalars['Date']['input'];
  vehicleId: Scalars['String']['input'];
};

export type JedlixChargingHistoryLocation = {
  __typename?: 'JedlixChargingHistoryLocation';
  chargingLocationId: Scalars['String']['output'];
  country: CountryCode;
  priceArea?: Maybe<EnergyBiddingZone>;
};

export type JedlixChargingHistoryPayload = {
  __typename?: 'JedlixChargingHistoryPayload';
  chargingLocations: Array<Maybe<JedlixChargingHistoryLocation>>;
  chargingSessionsInInterval: Array<Maybe<ChargingSession>>;
};

export type JedlixChargingLocation = {
  __typename?: 'JedlixChargingLocation';
  address: JedlixAddress;
  coordinates: LatLng;
  id: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};

export type JedlixChargingLocationInput = {
  chargingLocationId: Scalars['String']['input'];
};

export type JedlixChargingLocationPayload = {
  __typename?: 'JedlixChargingLocationPayload';
  chargingLocation: JedlixChargingLocation;
};

export type JedlixChargingLocationsPayload = {
  __typename?: 'JedlixChargingLocationsPayload';
  chargingLocations: Array<JedlixChargingLocation>;
};

export type JedlixConnection = {
  __typename?: 'JedlixConnection';
  id: Scalars['String']['output'];
  isFinished: Scalars['Boolean']['output'];
  redirectInfo?: Maybe<JedlixConnectionRedirectInfo>;
  redirectUrl?: Maybe<Scalars['String']['output']>;
  startUrl?: Maybe<Scalars['String']['output']>;
};

export type JedlixConnectionRedirectInfo = {
  __typename?: 'JedlixConnectionRedirectInfo';
  includeBody?: Maybe<Scalars['Boolean']['output']>;
  includeCookies: Array<Maybe<Scalars['String']['output']>>;
  includeRedirectUrl?: Maybe<Scalars['Boolean']['output']>;
};

export type JedlixNotification = {
  __typename?: 'JedlixNotification';
  createdAt: Scalars['Date']['output'];
  dismissedAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['String']['output'];
  isDismissable: Scalars['Boolean']['output'];
  messageContext: Scalars['String']['output'];
  notificationType: JedlixNotificationType;
  title: Scalars['String']['output'];
  type: MessageType;
};

export type JedlixNotificationType =
  | 'BANNER'
  | 'EMAIL'
  | 'PUSH_NOTIFICATION'
  | 'SMS';

export type JedlixUserPayload = {
  __typename?: 'JedlixUserPayload';
  chargingLocations?: Maybe<Array<Maybe<JedlixChargingLocation>>>;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  locale: Scalars['String']['output'];
};

export type JedlixVehicle = {
  __typename?: 'JedlixVehicle';
  capabilities: VehicleCapabilities;
  chargeState?: Maybe<JedlixChargeState>;
  details?: Maybe<VehicleInfo>;
  id: Scalars['String']['output'];
  isConnectable: Scalars['Boolean']['output'];
  isConnected: Scalars['Boolean']['output'];
};

export type LatLng = {
  __typename?: 'LatLng';
  latitude: Scalars['Float']['output'];
  longitude: Scalars['Float']['output'];
};

export type LatLngInput = {
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
};

export type ListJedlixVehiclesPayload = {
  __typename?: 'ListJedlixVehiclesPayload';
  vehicles: Array<Maybe<JedlixVehicle>>;
};

export type Location = {
  __typename?: 'Location';
  controllableIds: Array<Maybe<Scalars['String']['output']>>;
  coordinates: LatLng;
  deviceId?: Maybe<Scalars['String']['output']>;
  dso?: Maybe<DeliveryChargeModelId>;
  hourlyEnergyConsumptionLimit?: Maybe<Scalars['Float']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type LocationAvailableConfiguredIntegrationVendorVehicleLocationState = {
  __typename?: 'LocationAvailableConfiguredIntegrationVendorVehicleLocationState';
  lastUpdatedAt: Scalars['String']['output'];
  latitude: Scalars['Float']['output'];
  longitude: Scalars['Float']['output'];
  type: ConfiguredIntegrationVendorVehicleLocationStateType;
};

export type LoginInput = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type LoginPayload = {
  __typename?: 'LoginPayload';
  session: SessionInfo;
  user: UserInfo;
};

export type Measurement = {
  __typename?: 'Measurement';
  /** Active energy exported, in Wh */
  activeEnergyExported?: Maybe<Scalars['Float']['output']>;
  /** Active energy imported, in Wh */
  activeEnergyImported?: Maybe<Scalars['Float']['output']>;
  /** Active power export, in W */
  activePowerExport?: Maybe<Scalars['Float']['output']>;
  /** Active power import, in W */
  activePowerImport?: Maybe<Scalars['Float']['output']>;
  /** Current, phase 1, in A */
  currentPhase1?: Maybe<Scalars['Float']['output']>;
  /** Current, phase 2, in A */
  currentPhase2?: Maybe<Scalars['Float']['output']>;
  /** Current, phase 3, in A */
  currentPhase3?: Maybe<Scalars['Float']['output']>;
  /** Device ID */
  deviceId?: Maybe<Scalars['String']['output']>;
  /** Reactive energy exported, in varh */
  reactiveEnergyExported?: Maybe<Scalars['Float']['output']>;
  /** Reactive energy imported, in varh */
  reactiveEnergyImported?: Maybe<Scalars['Float']['output']>;
  /** Reactive power export, in var */
  reactivePowerExport?: Maybe<Scalars['Float']['output']>;
  /** Reactive power import, in var */
  reactivePowerImport?: Maybe<Scalars['Float']['output']>;
  /** Time in UTC */
  time: Scalars['Date']['output'];
  /** Voltage, phase 1, in V */
  voltagePhase1?: Maybe<Scalars['Float']['output']>;
  /** Voltage, phase 2, in V */
  voltagePhase2?: Maybe<Scalars['Float']['output']>;
  /** Voltage, phase 3, in V */
  voltagePhase3?: Maybe<Scalars['Float']['output']>;
};

export type MessageType =
  | 'AUDI_NOT_PAIRED'
  | 'AUDI_PAIRING_NOT_COMPLETE'
  | 'AUDI_REMOTE_ACCESS_DISABLED'
  | 'AUDI_REMOTE_ACCRESS_DISABLED_BANNER'
  | 'CAR_NOT_FOUND'
  | 'CAR_NOT_REACHABLE'
  | 'CHECK_CAR_ACTIVATION'
  | 'END_SESSION'
  | 'GEO_LOCATION_DISABLED'
  | 'INVALID_CREDENTIALS'
  | 'INVALID_CREDENTIALS_BANNER'
  | 'INVALID_UTILITY_CONTRACT'
  | 'MY_RENAULT_ACCESS_REVOKED'
  | 'READY_FOR_PAIRING'
  | 'RECEIVED_NO_POWER'
  | 'RECONNECT_UTILITY_CONTRACT'
  | 'SESSION_IMPOSSIBLE'
  | 'START_SESSION'
  | 'ZE_INTERACTIVE_EXPIRES_SOON'
  | 'ZE_INTERACTIVE_TO_BE_ACTIVATED';

/** Metadata about our services */
export type Metadata = {
  __typename?: 'Metadata';
  /** Required version to run the app. The app should block the user from using an older version than this. */
  requiredAppVersion: Scalars['String']['output'];
  /** Suggested version to run the app. The app will present the user with information about expecting errors when running older versions of the app. */
  suggestedAppVersion: Scalars['String']['output'];
};

export type MeterProducer =
  | 'AIDON'
  | 'EMBRIQ'
  | 'KAIFA'
  | 'KAMSTRUP'
  | 'UNRECOGNIZED';

export type MillSignInInput = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type MillSignInPayload = {
  __typename?: 'MillSignInPayload';
  success: Scalars['Boolean']['output'];
};

export type Month =
  | 'APRIL'
  | 'AUGUST'
  | 'DECEMBER'
  | 'FEBRUARY'
  | 'JANUARY'
  | 'JULY'
  | 'JUNE'
  | 'MARCH'
  | 'MAY'
  | 'NOVEMBER'
  | 'OCTOBER'
  | 'SEPTEMBER';

export type MonthRange = {
  __typename?: 'MonthRange';
  /** 1-12, including start */
  from: Scalars['Int']['output'];
  /** 1-12, exluding end */
  until: Scalars['Int']['output'];
};

export type MonthWeights = {
  __typename?: 'MonthWeights';
  april: Scalars['Float']['output'];
  august: Scalars['Float']['output'];
  december: Scalars['Float']['output'];
  february: Scalars['Float']['output'];
  january: Scalars['Float']['output'];
  july: Scalars['Float']['output'];
  june: Scalars['Float']['output'];
  march: Scalars['Float']['output'];
  may: Scalars['Float']['output'];
  november: Scalars['Float']['output'];
  october: Scalars['Float']['output'];
  september: Scalars['Float']['output'];
};

/**
 * Capacity model where (e.g. 3) daily maximas for a month is averaged and used to
 * determine the capacity step. The steps has a range in Wh with a price, and
 * there is also a field defining the price for when all capacity steps are
 * exceeded.
 */
export type MonthlyPeaksAtDifferentDays = {
  __typename?: 'MonthlyPeaksAtDifferentDays';
  capacitySteps: Array<CapacityStep>;
  /**
   * The number of daily peaks that will be averaged to find the capacity step.
   * This is normally three, but there are a few cases using 1.
   */
  peaksPerMonth: Scalars['Int']['output'];
  /**
   * Normally, there is an upper limit, above which there is a costly price.
   * Sometimes, the grid companies references a list of commercial capacity steps
   * when the consumtion is high, but we assume the customers are below the extra
   * commercial steps and "wrongly" set the highest of the private capacity step
   * prices, for these few cases.
   */
  priceAboveCapacitySteps: PriceSpecification;
  type: CapacityModelType;
};

/**
 * Capacity model where (e.g. 3) daily maximas for a month is averaged and
 * multiplied by `pricePerKwhForAverageOfPeaks` and added to `baseFeePerYear`.
 *
 * Capacity model used by SkiakerNett, but with only one maxima.
 *
 * <https://skiakernett.no/nettleige/>
 */
export type MonthlyPeaksAtDifferentDaysLinearWithBase = {
  __typename?: 'MonthlyPeaksAtDifferentDaysLinearWithBase';
  baseFeePerYear: PriceSpecification;
  /** The number of daily peaks that will be averaged to find the capacity step. */
  peaksPerMonth: Scalars['Int']['output'];
  pricePerKwhForAverageOfPeaks: PriceSpecification;
  type: CapacityModelType;
};

export type Mutation = {
  __typename?: 'Mutation';
  addChargingLocation: AddChargingLocationPayload;
  addChargingPermission?: Maybe<AddChargingPermissionPayload>;
  addControllableForLocation: AddControllableForLocationPayload;
  addDevicePermissions?: Maybe<AddDevicePermissionsPayload>;
  addDeviceToken?: Maybe<Scalars['Void']['output']>;
  addEstimatedHourlyActiveEnergyLimitWarningAlertSetting: EstimatedHourlyActiveEnergyLimitWarningAlertSetting;
  addHourlyConsumptionLimitEstimationWarningAlertSetting: HourlyConsumptionLimitEstimationWarningAlertSetting;
  addIntermediaryApiKey: IntermediaryApiKeyPayload;
  addIntermediaryWebhookNotificationChannelToAlertSetting: AddIntermediaryWebhookNotificationChannelToAlertSettingPayload;
  addLocation: AddLocationPayload;
  addPriceWarningAlertSetting: PriceWarningAlertSetting;
  addPushNotificationChannelToAlertSetting: AddPushNotificationChannelToAlertSettingPayload;
  addUser: AddUserPayload;
  addUserSession: AddUserSessionPayload;
  cancelOrder: Order;
  claimDevice: DeviceInfo;
  commonSignIn: CommonSignInPayload;
  connectExternalUser: ConnectExternalUserPayload;
  connectJedlixVehicle: JedlixConnection;
  createDevice: DeviceInfo;
  createJedlixChargingLocation: JedlixChargingLocation;
  createJedlixUser: JedlixUserPayload;
  deleteAlertSetting: DeleteAlertSettingPayload;
  deleteChargingLocation: DeleteChargingLocationPayload;
  deleteDeviceName?: Maybe<DeviceInfo>;
  deleteJedlixChargingLocation?: Maybe<DeleteJedlixChargingLocationPayload>;
  deleteJedlixUser?: Maybe<DeleteJedlixUserPayload>;
  deleteJedlixVehicle?: Maybe<DeleteJedlixVehiclePayload>;
  deleteMe: DeleteMePayload;
  disconnectVendor: DisconnectVendorPayload;
  grantDeviceAccess: Permission;
  login: LoginPayload;
  millSignIn?: Maybe<MillSignInPayload>;
  orderDevice: OrderDevicePayload;
  processOrderForIntermediary?: Maybe<ProcessOrderForIntermediaryPayload>;
  register?: Maybe<Scalars['Boolean']['output']>;
  removeChargingPermission?: Maybe<RemoveChargingPermissionPayload>;
  removeControllableFromLocation: RemoveControllableFromLocationPayload;
  removeDevicePermissions?: Maybe<RemoveDevicePermissionsPayload>;
  removeDeviceToken?: Maybe<Scalars['Void']['output']>;
  removeIntermediaryApiKey: IntermediaryApiKeyPayload;
  removeLocation: RemoveLocationPayload;
  removeNotificationChannelFromAlertSetting: RemoveNotificationChannelFromAlertSettingPayload;
  removeUser: RemoveUserPayload;
  returnDevice: ReturnDevicePayload;
  revokeDeviceAccess?: Maybe<Scalars['Boolean']['output']>;
  sendEmailVerificationEmail?: Maybe<Scalars['Boolean']['output']>;
  sendResetPasswordEmail?: Maybe<Scalars['Boolean']['output']>;
  setCoordinatesForLocation: SetCoordinatesForLocationPayload;
  setDeviceActivationStatus: SetDeviceActivationStatusPayload;
  setDeviceForLocation: SetDeviceForLocationPayload;
  setEnergyConsumptionLimitForLocation: SetEnergyConsumptionLimitForLocationPayload;
  setIntermediaryWebhookSetting: SetIntermediaryWebhookSettingPayload;
  setNameForLocation: SetNameForLocationPayload;
  shipDevice: DeviceInfo;
  shipOrder: Order;
  startIntegrationSession: IntegrationSessionPayload;
  testWebhookSetting: TestWebhookSettingPayload;
  updateChargingLocation: UpdateChargingLocationPayload;
  /**
   * Updates the device name
   *
   * Since the `deviceName` input is mandatory, there is another mutation to delete the device name.
   */
  updateDeviceName?: Maybe<DeviceInfo>;
  /**
   * Update device owner information. Will not change the info about who the device was shipped to.
   *
   * `deviceOwner` will be replaced by the new input object, and missing fields will be removed.
   */
  updateDeviceOwner?: Maybe<DeviceInfo>;
  updateEstimatedHourlyActiveEnergyLimitWarningAlertSetting: UpdateEstimatedHourlyActiveEnergyLimitWarningAlertSettingPayload;
  updateHourlyConsumptionLimitEstimationWarningAlertSetting: UpdateHourlyConsumptionLimitEstimationWarningAlertSettingPayload;
  updateJedlixChargingLocation: JedlixChargingLocation;
  updatePreferredEnergyBiddingZone?: Maybe<Scalars['Boolean']['output']>;
  updateSmartChargingPolicy: UpdateSmartChargingPolicyPayload;
  zaptecClaimInstallation: ZaptecClaimInstallationPayload;
};


export type MutationAddChargingLocationArgs = {
  input: AddChargingLocationInput;
};


export type MutationAddChargingPermissionArgs = {
  input: AddChargingPermissionInput;
};


export type MutationAddControllableForLocationArgs = {
  input: AddControllableForLocationInput;
};


export type MutationAddDevicePermissionsArgs = {
  input: AddDevicePermissionsInput;
};


export type MutationAddDeviceTokenArgs = {
  deviceToken: Scalars['String']['input'];
};


export type MutationAddEstimatedHourlyActiveEnergyLimitWarningAlertSettingArgs = {
  input: AddEstimatedHourlyActiveEnergyLimitWarningAlertSettingInput;
};


export type MutationAddHourlyConsumptionLimitEstimationWarningAlertSettingArgs = {
  input: AddHourlyConsumptionLimitEstimationWarningAlertSettingInput;
};


export type MutationAddIntermediaryApiKeyArgs = {
  input: AddIntermediaryApiKeyInput;
};


export type MutationAddIntermediaryWebhookNotificationChannelToAlertSettingArgs = {
  input: AddIntermediaryWebhookNotificationChannelToAlertSettingInput;
};


export type MutationAddLocationArgs = {
  input: AddLocationInput;
};


export type MutationAddPriceWarningAlertSettingArgs = {
  input: AddPriceWarningAlertSettingInput;
};


export type MutationAddPushNotificationChannelToAlertSettingArgs = {
  input: AddPushNotificationChannelToAlertSettingInput;
};


export type MutationAddUserArgs = {
  input: AddUserInput;
};


export type MutationAddUserSessionArgs = {
  input: AddUserSessionInput;
};


export type MutationCancelOrderArgs = {
  input: CancelOrderInput;
};


export type MutationClaimDeviceArgs = {
  claimId: Scalars['ClaimId']['input'];
};


export type MutationCommonSignInArgs = {
  input: CommonSignInInput;
};


export type MutationConnectExternalUserArgs = {
  input: ConnectExternalUserInput;
};


export type MutationConnectJedlixVehicleArgs = {
  input?: InputMaybe<Scalars['Void']['input']>;
};


export type MutationCreateDeviceArgs = {
  input: DeviceInput;
};


export type MutationCreateJedlixChargingLocationArgs = {
  input: CreateJedlixChargingLocationInput;
};


export type MutationCreateJedlixUserArgs = {
  input: CreateJedlixUserInput;
};


export type MutationDeleteAlertSettingArgs = {
  input: DeleteAlertSettingInput;
};


export type MutationDeleteChargingLocationArgs = {
  input: DeleteChargingLocationInput;
};


export type MutationDeleteDeviceNameArgs = {
  deviceId: Scalars['String']['input'];
};


export type MutationDeleteJedlixChargingLocationArgs = {
  input: DeleteJedlixChargingLocationInput;
};


export type MutationDeleteJedlixUserArgs = {
  input: Scalars['Void']['input'];
};


export type MutationDeleteJedlixVehicleArgs = {
  input: DeleteJedlixVehicleInput;
};


export type MutationDeleteMeArgs = {
  input: DeleteMeInput;
};


export type MutationDisconnectVendorArgs = {
  input: DisconnectVendorInput;
};


export type MutationGrantDeviceAccessArgs = {
  input: GrantDeviceAccessInput;
};


export type MutationLoginArgs = {
  input: LoginInput;
};


export type MutationMillSignInArgs = {
  input: MillSignInInput;
};


export type MutationOrderDeviceArgs = {
  input: OrderDeviceInput;
};


export type MutationProcessOrderForIntermediaryArgs = {
  input: ProcessOrderForIntermediaryInput;
};


export type MutationRegisterArgs = {
  input: RegisterInput;
};


export type MutationRemoveChargingPermissionArgs = {
  input: RemoveChargingPermissionInput;
};


export type MutationRemoveControllableFromLocationArgs = {
  input: RemoveControllableFromLocationInput;
};


export type MutationRemoveDevicePermissionsArgs = {
  input: RemoveDevicePermissionsInput;
};


export type MutationRemoveDeviceTokenArgs = {
  deviceToken: Scalars['String']['input'];
};


export type MutationRemoveIntermediaryApiKeyArgs = {
  input: RemoveIntermediaryApiKeyInput;
};


export type MutationRemoveLocationArgs = {
  input: RemoveLocationInput;
};


export type MutationRemoveNotificationChannelFromAlertSettingArgs = {
  input: RemoveNotificationChannelFromAlertSettingInput;
};


export type MutationRemoveUserArgs = {
  input: RemoveUserInput;
};


export type MutationReturnDeviceArgs = {
  input: ReturnDeviceInput;
};


export type MutationRevokeDeviceAccessArgs = {
  input: RevokeDeviceAccessInput;
};


export type MutationSendEmailVerificationEmailArgs = {
  input: EmailActionInput;
};


export type MutationSendResetPasswordEmailArgs = {
  input: EmailActionInput;
};


export type MutationSetCoordinatesForLocationArgs = {
  input: SetCoordinatesForLocationInput;
};


export type MutationSetDeviceActivationStatusArgs = {
  input: SetDeviceActivationStatusInput;
};


export type MutationSetDeviceForLocationArgs = {
  input: SetDeviceForLocationInput;
};


export type MutationSetEnergyConsumptionLimitForLocationArgs = {
  input: SetEnergyConsumptionLimitForLocationInput;
};


export type MutationSetIntermediaryWebhookSettingArgs = {
  input: SetIntermediaryWebhookSettingInput;
};


export type MutationSetNameForLocationArgs = {
  input: SetNameForLocationInput;
};


export type MutationShipDeviceArgs = {
  deviceIdentifier: ShipDeviceIdentifierInput;
  intermediaryId: Scalars['String']['input'];
  shipTo?: InputMaybe<ShipToUserInput>;
  subscription: SubscriptionInput;
  webhookCallbackUrl?: InputMaybe<Scalars['String']['input']>;
};


export type MutationShipOrderArgs = {
  input: ShipOrderInput;
};


export type MutationStartIntegrationSessionArgs = {
  input: IntegrationSessionInput;
};


export type MutationTestWebhookSettingArgs = {
  input: TestWebhookSettingInput;
};


export type MutationUpdateChargingLocationArgs = {
  input: UpdateChargingLocationInput;
};


export type MutationUpdateDeviceNameArgs = {
  deviceId: Scalars['String']['input'];
  deviceName: Scalars['String']['input'];
};


export type MutationUpdateDeviceOwnerArgs = {
  deviceId: Scalars['String']['input'];
  deviceOwner: DeviceOwnerInput;
};


export type MutationUpdateEstimatedHourlyActiveEnergyLimitWarningAlertSettingArgs = {
  input: UpdateEstimatedHourlyActiveEnergyLimitWarningAlertSettingInput;
};


export type MutationUpdateHourlyConsumptionLimitEstimationWarningAlertSettingArgs = {
  input: UpdateHourlyConsumptionLimitEstimationWarningAlertSettingInput;
};


export type MutationUpdateJedlixChargingLocationArgs = {
  input: UpdateJedlixChargingLocationInput;
};


export type MutationUpdatePreferredEnergyBiddingZoneArgs = {
  deviceId: Scalars['String']['input'];
  preferredEnergyBiddingZone: EnergyBiddingZone;
};


export type MutationUpdateSmartChargingPolicyArgs = {
  input: UpdateSmartChargingPolicyInput;
};


export type MutationZaptecClaimInstallationArgs = {
  input: ZaptecClaimInstallationInput;
};

export type MyDevicesInput = {
  filter?: InputMaybe<DeviceFilter>;
};

export type NotConfiguredIntegrationVendorInfo = {
  __typename?: 'NotConfiguredIntegrationVendorInfo';
  status: IntegrationVendorStatus;
  vendor: IntegrationVendor;
};

export type NotificationChannel = IntermediaryWebhookNotificationChannel | PushNotificationChannel;

export type NotificationChannelType =
  | 'INTERMEDIARY_WEBHOOK'
  | 'PUSH_NOTIFICATION';

export type NotificationType =
  | 'PRICE_WARNING';

export type OptionalFieldsPostalAddress = {
  __typename?: 'OptionalFieldsPostalAddress';
  country?: Maybe<PostcodeCountry>;
  postcode?: Maybe<Scalars['String']['output']>;
  streetAddress?: Maybe<Scalars['String']['output']>;
};

export type OptionalFieldsPostalAddressInput = {
  country?: InputMaybe<PostcodeCountry>;
  postcode?: InputMaybe<Scalars['String']['input']>;
  streetAddress?: InputMaybe<Scalars['String']['input']>;
};

export type Order = {
  __typename?: 'Order';
  deviceId?: Maybe<Scalars['String']['output']>;
  intermediaryId: Scalars['String']['output'];
  orderId: Scalars['String']['output'];
  orderStatus: OrderStatus;
  recipient: PostalRecipient;
  subscriptionType: OrderSubscriptionType;
  trackingLink?: Maybe<Scalars['String']['output']>;
  type: DeviceType;
  updatedAt: Scalars['Date']['output'];
  webhookCallbackUrl?: Maybe<Scalars['String']['output']>;
};

export type OrderDeviceInput = {
  recipient: PostalRecipientInput;
  subscriptionType: OrderSubscriptionType;
  type: DeviceType;
  webhookCallbackUrl?: InputMaybe<Scalars['String']['input']>;
};

export type OrderDevicePayload = {
  __typename?: 'OrderDevicePayload';
  order: Order;
  ordersForUser: Array<Maybe<Order>>;
};

export type OrderInput = {
  orderId: Scalars['String']['input'];
};

export type OrderStatus =
  | 'CANCELLED'
  | 'IN_PROGRESS'
  | 'ORDERED'
  | 'SHIPPED';

export type OrderSubscriptionType =
  | 'BUSINESS'
  | 'PERSONAL';

export type OrderSummary = {
  __typename?: 'OrderSummary';
  intermediaryId: Scalars['String']['output'];
  ltemsToBeShipped: Scalars['Int']['output'];
  wifisToBeShipped: Scalars['Int']['output'];
};

export type OrderSummaryPayload = {
  __typename?: 'OrderSummaryPayload';
  orderSummary: Array<Maybe<OrderSummary>>;
};

export type OrdersForUserInput = {
  externalUserId: Scalars['String']['input'];
};

export type OrdersForUserPayload = {
  __typename?: 'OrdersForUserPayload';
  orders: Array<Order>;
};

/** User information for device owners */
export type OwnedByUserInfo = {
  __typename?: 'OwnedByUserInfo';
  emailAddress?: Maybe<Scalars['String']['output']>;
  externalUserId?: Maybe<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  postalAddress?: Maybe<OptionalFieldsPostalAddress>;
};

/** Our permission system works across different types of resources and different types of actors */
export type Permission = {
  __typename?: 'Permission';
  /** The id of the entity being permitted, e.g. a userId */
  entityId: Scalars['String']['output'];
  /** The id of the resource the permission is targeting, e.g. a deviceId */
  resourceId: Scalars['String']['output'];
  /** Permissions to resources, like read or write, are defined by scopes */
  scopes: Array<Scalars['String']['output']>;
};

export type PhasePrices = {
  __typename?: 'PhasePrices';
  /** Prices for 230V networks */
  voltage230: PhaseVoltagePrices;
  /** Prices for 400V networks */
  voltage400: PhaseVoltagePrices;
};

export type PhaseVoltagePrices = {
  __typename?: 'PhaseVoltagePrices';
  currentRangePrices: Array<CurrentRangePrice>;
  yearlyPriceAboveCurrentRanges: PriceSpecification;
};

export type PlannedSmartChargingStatus = {
  __typename?: 'PlannedSmartChargingStatus';
  externalStart: Scalars['Boolean']['output'];
  plan: SmartChargingPlan;
  planningStatus: SmartChargingPlanningStatusType;
  type: SmartChargingStatusType;
};

export type PostalAddress = {
  __typename?: 'PostalAddress';
  country: PostcodeCountry;
  postcode: Scalars['String']['output'];
  streetAddress: Scalars['String']['output'];
};

export type PostalAddressInput = {
  country: PostcodeCountry;
  postcode: Scalars['String']['input'];
  streetAddress: Scalars['String']['input'];
};

export type PostalRecipient = {
  __typename?: 'PostalRecipient';
  email: Scalars['String']['output'];
  externalUserId: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  phoneNumber: Scalars['String']['output'];
  postalAddress: PostalRecipientPostalAddress;
};

export type PostalRecipientInput = {
  /** Max-length 60 characters */
  email: Scalars['String']['input'];
  externalUserId: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
  postalAddress: PostalRecipientPostalAddressInput;
};

export type PostalRecipientPostalAddress = {
  __typename?: 'PostalRecipientPostalAddress';
  addressLine1: Scalars['String']['output'];
  addressLine2?: Maybe<Scalars['String']['output']>;
  city: Scalars['String']['output'];
  country: CountryCode;
  postalCode: Scalars['String']['output'];
};

export type PostalRecipientPostalAddressInput = {
  /** Max-length 35 characters */
  addressLine1: Scalars['String']['input'];
  /** Max-length 35 characters */
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  /** Max-length 35 characters */
  city: Scalars['String']['input'];
  country: CountryCode;
  /** 4 digits if country is Norway */
  postalCode: Scalars['String']['input'];
};

export type PostcodeCountry =
  | 'NORWAY';

export type PriceSpecification = {
  __typename?: 'PriceSpecification';
  /** The actual price, this should be including VAT (MVA) where it applies */
  price: Scalars['Float']['output'];
  /** The currency of the given price, usually in NOK */
  priceCurrency: Currency;
  /** The prices without VAT (MVA) */
  priceExcludingVat: Scalars['Float']['output'];
  /** VAT, or "Merverdiavgift" */
  vat: Scalars['Float']['output'];
};

export type PriceWarningAlertSetting = {
  __typename?: 'PriceWarningAlertSetting';
  alertType: AlertType;
  createdBy: AlertSettingCreator;
  energyBiddingZone: EnergyBiddingZone;
  id: Scalars['ID']['output'];
  notificationChannels: Array<NotificationChannel>;
};

export type PriceWarningPushNotificationSetting = {
  __typename?: 'PriceWarningPushNotificationSetting';
  deviceId: Scalars['String']['output'];
  notificationType: NotificationType;
  wantsNotification: Scalars['Boolean']['output'];
};

export type PricesAndTaxes = {
  __typename?: 'PricesAndTaxes';
  priceExcludingEnergyTaxes: PriceSpecification;
  priceIncludingEnergyTaxes: PriceSpecification;
  taxes: DeliveryChargeEnergyTaxes;
};

/**
 * Price and tax info for part of a day, includes a specification of which hours
 * the price is for.
 */
export type PricesAndTaxesForPartOfDay = {
  __typename?: 'PricesAndTaxesForPartOfDay';
  hours: HourRange;
  pricesAndTaxes: PricesAndTaxes;
};

/**
 * The prices for a part of year, will include the price specifiations and a
 * for what months the prices is for
 */
export type PricesForPartOfYear = {
  __typename?: 'PricesForPartOfYear';
  months: MonthRange;
  prices: DayAndNightPrices;
};

/**
 * The prices for a part of year without differentiating day and night, will include the price specifiations and a
 * for what months the prices is for
 */
export type PricesForPartOfYearWithoutDayAndNight = {
  __typename?: 'PricesForPartOfYearWithoutDayAndNight';
  months: MonthRange;
  /** @deprecated The field is not complete, use the adjacent field `pricesAndTaxes` */
  price: PriceSpecification;
  pricesAndTaxes: PricesAndTaxes;
};

export type ProcessOrderForIntermediaryInput = {
  deviceType: DeviceType;
  intermediaryId: Scalars['String']['input'];
};

export type ProcessOrderForIntermediaryPayload = {
  __typename?: 'ProcessOrderForIntermediaryPayload';
  order?: Maybe<Order>;
};

export type PushNotificationChannel = {
  __typename?: 'PushNotificationChannel';
  forUserWithId: Scalars['String']['output'];
  type: NotificationChannelType;
};

export type PushNotificationSetting = PriceWarningPushNotificationSetting;

export type Query = {
  __typename?: 'Query';
  _placeholder: Scalars['String']['output'];
  chargingStatistics: ChargingStatisticsPayload;
  checkJedlixConnection: JedlixConnection;
  currentIntermediary: CurrentIntermediaryPayload;
  /**
   * Day ahead prices
   *
   * Get price data from the Entso-E transparency platrom for the time range
   * specified.
   *
   * Prices are converted to NOK and VAT is added for the zones where
   * this is applicable.
   *
   * Read more about the transparency platform of the European Network of
   * Transmission System Operators at https://transparency.entsoe.eu/
   */
  dayAheadPrices: Array<Maybe<EnergyPriceOfHour>>;
  deliveryChargeModel: DeliveryChargeModel;
  device: DeviceQueryPayload;
  /** Generates an authorization URL which can be embedded in a webview which in turn will allow an end user to grant our API access to control their vehicle/charger */
  integrationAuthorizationUrl: IntegrationAuthorizationUrlResponse;
  jedlixActiveNotifications: JedlixActiveNotificationsPayload;
  jedlixChargingHistory: JedlixChargingHistoryPayload;
  jedlixChargingLocation: JedlixChargingLocationPayload;
  jedlixChargingLocations: JedlixChargingLocationsPayload;
  jedlixUser: JedlixUserPayload;
  listControllablesForUser: ControllablesForUserPayload;
  listJedlixVehicles: ListJedlixVehiclesPayload;
  locations: Array<Location>;
  me: UserInfo;
  /**
   * Get data from the latest measurements using deviceId or meterId (or both)
   *
   * * If only deviceId is given, reports for the most recent meterId that the device was connected to in the requested range will be returned
   * * If only meterId is given, reports for this meter will be returned from the most recent deviceId that was connected to this meter in the requested range
   * * If both meterId and deviceId is given, reports will only be returned if the given deviceId was connected to the given meterId in the requested range
   *
   * In all cases the accessToken needs permissions to read reports for the corresponding deviceId.
   *
   * Measurements are only available for two to three hours.
   */
  measurements: Array<Measurement>;
  /** Get metadata about services, like minimum required app requiredAppVersion */
  metadata: Metadata;
  myDevices: Array<DeviceInfo>;
  order: Order;
  orderSummary: OrderSummaryPayload;
  ordersForUser: OrdersForUserPayload;
  /** Get permissions containing requested scopes for the authenticated user */
  permissions: Array<Permission>;
  /**
   * Get hourly or daily reports using deviceId or meterId (or both)
   *
   * * If only deviceId is given, reports for the most recent meterId that the device was connected to in the requested range will be returned
   * * If only meterId is given, reports for this meter will be returned from the most recent deviceId that was connected to this meter in the requested range
   * * If both meterId and deviceId is given, reports will only be returned if the given deviceId was connected to the given meterId in the requested range
   *
   * In all cases the accessToken needs permissions to read reports for the corresponding deviceId.
   */
  reports: Array<Report>;
  user: UserPayload;
  vehicleSmartChargingStatus: VehicleSmartChargingStatusPayload;
};


export type QueryChargingStatisticsArgs = {
  input: ChargingStatisticsInput;
};


export type QueryCheckJedlixConnectionArgs = {
  input: CheckJedlixConnectionInput;
};


export type QueryDayAheadPricesArgs = {
  from: Scalars['Date']['input'];
  to: Scalars['Date']['input'];
  zone: EnergyBiddingZone;
};


export type QueryDeliveryChargeModelArgs = {
  input: DeliveryChargeModelInput;
};


export type QueryDeviceArgs = {
  input: DeviceQueryInput;
};


export type QueryIntegrationAuthorizationUrlArgs = {
  input: IntegrationAuthorizationUrlInput;
};


export type QueryJedlixActiveNotificationsArgs = {
  input?: InputMaybe<Scalars['Void']['input']>;
};


export type QueryJedlixChargingHistoryArgs = {
  input: JedlixChargingHistoryInput;
};


export type QueryJedlixChargingLocationArgs = {
  input: JedlixChargingLocationInput;
};


export type QueryJedlixChargingLocationsArgs = {
  input?: InputMaybe<Scalars['Void']['input']>;
};


export type QueryJedlixUserArgs = {
  input: Scalars['Void']['input'];
};


export type QueryListControllablesForUserArgs = {
  input?: InputMaybe<Scalars['Void']['input']>;
};


export type QueryListJedlixVehiclesArgs = {
  input?: InputMaybe<Scalars['Void']['input']>;
};


export type QueryMeasurementsArgs = {
  deviceId?: InputMaybe<Scalars['String']['input']>;
  from: Scalars['String']['input'];
  meterId?: InputMaybe<Scalars['String']['input']>;
  to: Scalars['String']['input'];
};


export type QueryMyDevicesArgs = {
  input?: InputMaybe<MyDevicesInput>;
};


export type QueryOrderArgs = {
  input: OrderInput;
};


export type QueryOrderSummaryArgs = {
  input?: InputMaybe<Scalars['Void']['input']>;
};


export type QueryOrdersForUserArgs = {
  input: OrdersForUserInput;
};


export type QueryPermissionsArgs = {
  scopes: Array<Scalars['String']['input']>;
};


export type QueryReportsArgs = {
  deviceId?: InputMaybe<Scalars['String']['input']>;
  from: Scalars['String']['input'];
  interval: Interval;
  meterId?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Timezone>;
  to: Scalars['String']['input'];
};


export type QueryUserArgs = {
  input: UserInput;
};


export type QueryVehicleSmartChargingStatusArgs = {
  input: VehicleSmartChargingStatusInput;
};

export type Range = {
  __typename?: 'Range';
  max: Scalars['Float']['output'];
  mean: Scalars['Float']['output'];
  min: Scalars['Float']['output'];
};

export type RecentMeter = {
  __typename?: 'RecentMeter';
  firstSeenAt: Scalars['Date']['output'];
  meterId: Scalars['MeterId']['output'];
  producer: MeterProducer;
  shortMeterId: Scalars['String']['output'];
};

export type RegisterInput = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
  redirectUri?: InputMaybe<Scalars['String']['input']>;
};

export type RemoveChargingPermissionInput = {
  userIdentifier: UserIdentifierInput;
};

export type RemoveChargingPermissionPayload = {
  __typename?: 'RemoveChargingPermissionPayload';
  modifiedPermission: Permission;
  success: Scalars['Boolean']['output'];
  user: UserInfo;
};

export type RemoveControllableFromLocationInput = {
  controllableId: Scalars['String']['input'];
  locationId: Scalars['String']['input'];
};

export type RemoveControllableFromLocationPayload = {
  __typename?: 'RemoveControllableFromLocationPayload';
  location: Location;
};

export type RemoveDevicePermissionsInput = {
  deviceIdentifiers: Array<DeviceIdentifierInput>;
  userIdentifier: UserIdentifierInput;
};

export type RemoveDevicePermissionsPayload = {
  __typename?: 'RemoveDevicePermissionsPayload';
  modifiedPermissions: Array<Permission>;
  success: Scalars['Boolean']['output'];
  user: UserInfo;
};

export type RemoveIntermediaryApiKeyInput = {
  id: Scalars['String']['input'];
};

export type RemoveIntermediaryUserInput = {
  externalId: Scalars['String']['input'];
};

export type RemoveIntermediaryUserPayload = {
  __typename?: 'RemoveIntermediaryUserPayload';
  user: UserInfo;
};

export type RemoveLocationInput = {
  locationId: Scalars['String']['input'];
};

export type RemoveLocationPayload = {
  __typename?: 'RemoveLocationPayload';
  location: Location;
};

export type RemoveNotificationChannelFromAlertSettingInput = {
  alertSettingId: Scalars['ID']['input'];
  notificationChannelType: NotificationChannelType;
};

export type RemoveNotificationChannelFromAlertSettingPayload = {
  __typename?: 'RemoveNotificationChannelFromAlertSettingPayload';
  success: Scalars['Boolean']['output'];
};

export type RemoveUserInput = {
  userIdentifier: UserIdentifierInput;
};

export type RemoveUserPayload = {
  __typename?: 'RemoveUserPayload';
  success: Scalars['Boolean']['output'];
  user: UserInfo;
};

export type Report = {
  __typename?: 'Report';
  /**
   * Active energy exported in the time period, in Wh
   *
   * Not available for the _current hour_
   */
  activeEnergyExported?: Maybe<Scalars['Float']['output']>;
  /**
   * Active energy imported in the time period, in Wh
   *
   * Not available for the _current hour_
   */
  activeEnergyImported?: Maybe<Scalars['Float']['output']>;
  /** Average of ActivePowerExport values in the time period, in W */
  averageActivePowerExport?: Maybe<Scalars['Float']['output']>;
  /** Average of ActivePowerImport values in the time period, in W */
  averageActivePowerImport?: Maybe<Scalars['Float']['output']>;
  /** Cumulative values of the active energy export in Wh at the start of the interval */
  cumulativeActiveEnergyExportedAtIntervalStart?: Maybe<Scalars['Float']['output']>;
  /** Cumulative values of the active energy import in Wh at the start of the interval */
  cumulativeActiveEnergyImportedAtIntervalStart?: Maybe<Scalars['Float']['output']>;
  /** Cumulative values of the reactive energy export in varh at the start of the interval */
  cumulativeReactiveEnergyExportedAtIntervalStart?: Maybe<Scalars['Float']['output']>;
  /** Cumulative values of the reactive energy import in varh at the start of the interval */
  cumulativeReactiveEnergyImportedAtIntervalStart?: Maybe<Scalars['Float']['output']>;
  /** Report start time, in UTC */
  from: Scalars['Date']['output'];
  /** Report end time, in UTC */
  to: Scalars['Date']['output'];
};

export type ReturnDeviceInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  deviceId: Scalars['String']['input'];
  hasBeenUsed: Scalars['Boolean']['input'];
};

export type ReturnDevicePayload = {
  __typename?: 'ReturnDevicePayload';
  device: DeviceInfo;
  success: Scalars['Boolean']['output'];
};

/** Return information */
export type ReturnInfo = {
  __typename?: 'ReturnInfo';
  comment?: Maybe<Scalars['String']['output']>;
  hasBeenUsed?: Maybe<Scalars['Boolean']['output']>;
  returnedAt?: Maybe<Scalars['Date']['output']>;
};

export type RevokeDeviceAccessInput = {
  deviceId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type RushPricingDay = {
  __typename?: 'RushPricingDay';
  hours: HourRange;
  price: PriceSpecification;
};

/** The additional rush prices in a list, between two dates */
export type RushPricingPeriod = {
  __typename?: 'RushPricingPeriod';
  dateRange: DateRange;
  days: RushPricingWeek;
  holidaysIncluded: Scalars['Boolean']['output'];
};

export type RushPricingWeek = {
  __typename?: 'RushPricingWeek';
  friday?: Maybe<RushPricingDay>;
  monday?: Maybe<RushPricingDay>;
  saturday?: Maybe<RushPricingDay>;
  sunday?: Maybe<RushPricingDay>;
  thursday?: Maybe<RushPricingDay>;
  tuesday?: Maybe<RushPricingDay>;
  wednesday?: Maybe<RushPricingDay>;
};

export type Search = {
  deviceId?: InputMaybe<Scalars['String']['input']>;
  externalUserId?: InputMaybe<Scalars['String']['input']>;
  meterId?: InputMaybe<Scalars['String']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
};

/** There are different prices for summer and winter, day and night */
export type SeasonalPrices = {
  __typename?: 'SeasonalPrices';
  summer: PricesForPartOfYear;
  winter: PricesForPartOfYear;
};

/** There are different prices for summer and winter, but not day and night */
export type SeasonalPricesWithoutDayAndNight = {
  __typename?: 'SeasonalPricesWithoutDayAndNight';
  summer: PricesForPartOfYearWithoutDayAndNight;
  winter: PricesForPartOfYearWithoutDayAndNight;
};

/**
 * Energy model were users can select type of energy model.
 *
 * Companies might have multiple types of energy models, and
 * let users select which model they would like use.
 */
export type SelectableEnergyModel = {
  __typename?: 'SelectableEnergyModel';
  constantPrice?: Maybe<ConstantPrice>;
  differentPricesDayAndNight?: Maybe<DifferentPricesDayAndNight>;
  differentPricesSeasonal?: Maybe<DifferentPricesSeasonal>;
  differentPricesSeasonalDayAndNight?: Maybe<DifferentPricesSeasonalDayAndNight>;
  type: EnergyModelType;
};

export type SessionInfo = {
  __typename?: 'SessionInfo';
  accessToken: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};

export type SessionInfoWithoutAccessToken = {
  __typename?: 'SessionInfoWithoutAccessToken';
  startOfSecret: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};

export type SetCoordinatesForLocationInput = {
  coordinates: LatLngInput;
  locationId: Scalars['String']['input'];
};

export type SetCoordinatesForLocationPayload = {
  __typename?: 'SetCoordinatesForLocationPayload';
  location: Location;
};

export type SetDeviceActivationStatusInput = {
  activationStatus: ActivationStatus;
  deviceIdentifier: DeviceIdentifierInput;
};

export type SetDeviceActivationStatusPayload = {
  __typename?: 'SetDeviceActivationStatusPayload';
  device: DeviceInfo;
  success: Scalars['Boolean']['output'];
};

export type SetDeviceForLocationInput = {
  deviceIdentifier: DeviceQueryIdentifier;
  locationId: Scalars['String']['input'];
};

export type SetDeviceForLocationPayload = {
  __typename?: 'SetDeviceForLocationPayload';
  location: Location;
};

export type SetEnergyConsumptionLimitForLocationInput = {
  limit: Scalars['Int']['input'];
  locationId: Scalars['String']['input'];
};

export type SetEnergyConsumptionLimitForLocationPayload = {
  __typename?: 'SetEnergyConsumptionLimitForLocationPayload';
  location: Location;
};

export type SetIntermediaryWebhookSettingInput = {
  intermediaryId?: InputMaybe<Scalars['String']['input']>;
  secret: Scalars['String']['input'];
};

export type SetIntermediaryWebhookSettingPayload = {
  __typename?: 'SetIntermediaryWebhookSettingPayload';
  webhookSetting: IntermediaryWebhookSetting;
};

export type SetNameForLocationInput = {
  locationId: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type SetNameForLocationPayload = {
  __typename?: 'SetNameForLocationPayload';
  location: Location;
};

export type ShipDeviceIdentifierInput = {
  claimId?: InputMaybe<Scalars['ClaimId']['input']>;
  deviceId?: InputMaybe<Scalars['DeviceId']['input']>;
};

export type ShipOrderInput = {
  deviceIdentifier: ShipDeviceIdentifierInput;
  orderId: Scalars['String']['input'];
  trackingLink: Scalars['String']['input'];
};

export type ShipToUserInput = {
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  externalUserId?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  postalAddress: PostalAddressInput;
  trackingLink?: InputMaybe<Scalars['String']['input']>;
};

/** User information for tracking shipment and activation */
export type ShippedToUserInfo = {
  __typename?: 'ShippedToUserInfo';
  emailAddress?: Maybe<Scalars['String']['output']>;
  externalUserId?: Maybe<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  postalAddress: PostalAddress;
  trackingLink?: Maybe<Scalars['String']['output']>;
};

export type SmartChargingConsideration = {
  __typename?: 'SmartChargingConsideration';
  hasTimeEstimate: Scalars['Boolean']['output'];
  isCharging: Scalars['Boolean']['output'];
  isPluggedIn: Scalars['Boolean']['output'];
  recentlyAtChargingLocation: Scalars['Boolean']['output'];
};

export type SmartChargingPlan = {
  __typename?: 'SmartChargingPlan';
  endedAt?: Maybe<Scalars['String']['output']>;
  estimatedFinishAt: Scalars['String']['output'];
  nonSmartCost: Scalars['Float']['output'];
  smartCost?: Maybe<Scalars['Float']['output']>;
  startAt?: Maybe<Scalars['String']['output']>;
  startConfirmedAt?: Maybe<Scalars['String']['output']>;
  stopConfirmedAt?: Maybe<Scalars['String']['output']>;
};

export type SmartChargingPlanningStatusType =
  | 'PLAN_ENDED_DEADLINE_CHANGED'
  | 'PLAN_ENDED_DISABLED'
  | 'PLAN_ENDED_FAILED'
  | 'PLAN_ENDED_FINISHED'
  | 'PLAN_ENDED_UNPLUGGED'
  | 'PLAN_EXECUTING_CHARGE_INTERRUPTED'
  | 'PLAN_EXECUTING_OVERRIDDEN'
  | 'PLAN_EXECUTING_STARTED'
  | 'PLAN_EXECUTING_STARTING'
  | 'PLAN_EXECUTING_START_FAILED'
  | 'PLAN_EXECUTING_STOPPED'
  | 'PLAN_EXECUTING_STOPPED_AWAITING_PRICES'
  | 'PLAN_EXECUTING_STOPPING'
  | 'PLAN_EXECUTING_STOP_FAILED';

export type SmartChargingPolicy = {
  __typename?: 'SmartChargingPolicy';
  deadlineInUtcTime: Scalars['String']['output'];
  isEnabled: Scalars['Boolean']['output'];
};

export type SmartChargingStatusType =
  | 'CONSIDERING'
  | 'DISABLED'
  | 'FULLY_CHARGED'
  | 'PLANNED';

/**
 * Subscriptions use websocket, and our websocket service responds at the current URL + `/subscriptions`
 *
 * We support both `graphql-ws` and `graphql-transport-ws` protocols.
 */
export type Subscription = {
  __typename?: 'Subscription';
  /** Subscribe to realtime measurements of a device. */
  realtimeMeasurement?: Maybe<Measurement>;
  /** Subscribe to realtime measurements of all devices you have access to. */
  realtimeMeasurementAll?: Maybe<Measurement>;
};


/**
 * Subscriptions use websocket, and our websocket service responds at the current URL + `/subscriptions`
 *
 * We support both `graphql-ws` and `graphql-transport-ws` protocols.
 */
export type SubscriptionRealtimeMeasurementArgs = {
  deviceId?: InputMaybe<Scalars['String']['input']>;
  meterId?: InputMaybe<Scalars['String']['input']>;
};


/**
 * Subscriptions use websocket, and our websocket service responds at the current URL + `/subscriptions`
 *
 * We support both `graphql-ws` and `graphql-transport-ws` protocols.
 */
export type SubscriptionRealtimeMeasurementAllArgs = {
  input?: InputMaybe<Scalars['Void']['input']>;
};

export type SubscriptionBilling = {
  __typename?: 'SubscriptionBilling';
  subscriptionType: SubscriptionType;
};

export type SubscriptionInput = {
  subscriptionType?: InputMaybe<SubscriptionType>;
};

export type SubscriptionType =
  | 'BUSINESS'
  | 'PERSONAL'
  | 'PILOT';

export type TestWebhookSettingInput = {
  callbackUrl: Scalars['String']['input'];
  intermediaryId?: InputMaybe<Scalars['String']['input']>;
};

export type TestWebhookSettingPayload = {
  __typename?: 'TestWebhookSettingPayload';
  success: Scalars['Boolean']['output'];
};

/** Time zones, as reported by the _Internet Assigned Numbers Authority_ (IANA) database, but using an underscore for the forward slash. */
export type Timezone =
  | 'EUROPE_OSLO'
  | 'UTC';

export type UpdateChargingLocationInput = {
  id: Scalars['ID']['input'];
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
  name: Scalars['String']['input'];
};

export type UpdateChargingLocationPayload = {
  __typename?: 'UpdateChargingLocationPayload';
  chargingLocation: ChargingLocation;
};

export type UpdateEstimatedHourlyActiveEnergyLimitWarningAlertSettingInput = {
  alertSettingId: Scalars['ID']['input'];
  limitInWattHours: Scalars['Int']['input'];
};

export type UpdateEstimatedHourlyActiveEnergyLimitWarningAlertSettingPayload = {
  __typename?: 'UpdateEstimatedHourlyActiveEnergyLimitWarningAlertSettingPayload';
  alertSetting: EstimatedHourlyActiveEnergyLimitWarningAlertSetting;
};

export type UpdateHourlyConsumptionLimitEstimationWarningAlertSettingInput = {
  alertSettingId: Scalars['ID']['input'];
  limitInWatts: Scalars['Int']['input'];
};

export type UpdateHourlyConsumptionLimitEstimationWarningAlertSettingPayload = {
  __typename?: 'UpdateHourlyConsumptionLimitEstimationWarningAlertSettingPayload';
  alertSetting: HourlyConsumptionLimitEstimationWarningAlertSetting;
};

export type UpdateJedlixChargingLocationInput = {
  address: JedlixAddressInput;
  chargingLocationId: Scalars['String']['input'];
  coordinates: LatLngInput;
};

export type UpdateSmartChargingPolicyInput = {
  /**
   * Time for when the charging should be finished by.
   * hours:minutes, e.g. 08:00 or 23:59, in UTC time
   */
  deadlineInUtcTime: Scalars['String']['input'];
  isEnabled: Scalars['Boolean']['input'];
  vehicleId: Scalars['String']['input'];
};

export type UpdateSmartChargingPolicyPayload = {
  __typename?: 'UpdateSmartChargingPolicyPayload';
  smartChargingPolicy: SmartChargingPolicy;
};

export type User = {
  __typename?: 'User';
  id: Scalars['String']['output'];
  verifiedIdentities?: Maybe<Array<Identity>>;
};

export type UserIdentifier = ExternalUserIdentifier;

export type UserIdentifierInput = {
  externalUser?: InputMaybe<ExternalUserIdentifierInput>;
};

export type UserInfo = {
  __typename?: 'UserInfo';
  alertSettings?: Maybe<Array<AlertSetting>>;
  chargingLocations?: Maybe<Array<ChargingLocation>>;
  id: Scalars['ID']['output'];
  intermediaries?: Maybe<Array<IntermediaryInfo>>;
  linkedIntegrationVendors?: Maybe<Array<IntegrationVendorInfo>>;
  sessions?: Maybe<Array<SessionInfoWithoutAccessToken>>;
  userIdentifier?: Maybe<UserIdentifier>;
  verifiedEmail?: Maybe<Scalars['String']['output']>;
  verifiedIdentities?: Maybe<Array<Identity>>;
};


export type UserInfoIntermediariesArgs = {
  filter?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type UserInput = {
  userIdentifier: UserIdentifierInput;
};

export type UserNotAuthenticatedError = CustomError & {
  __typename?: 'UserNotAuthenticatedError';
  message: Scalars['String']['output'];
  type: ErrorType;
};

export type UserNotPermittedError = CustomError & {
  __typename?: 'UserNotPermittedError';
  entityId?: Maybe<Scalars['String']['output']>;
  message: Scalars['String']['output'];
  resourceId?: Maybe<Scalars['String']['output']>;
  scope?: Maybe<Scalars['String']['output']>;
  type: ErrorType;
};

export type UserPayload = {
  __typename?: 'UserPayload';
  user: UserInfo;
};

export type VehicleCapabilities = {
  __typename?: 'VehicleCapabilities';
  charge: Scalars['Boolean']['output'];
  chargeState: Scalars['Boolean']['output'];
  desiredStateOfCharge: Scalars['Boolean']['output'];
  geoLocation: Scalars['Boolean']['output'];
  pullTelemetry: Scalars['Boolean']['output'];
  startStopCharging: Scalars['Boolean']['output'];
  stateOfCharge: Scalars['Boolean']['output'];
};

export type VehicleChargingStatus =
  | 'CHARGING'
  | 'COMPLETE'
  | 'DISCONNECTED'
  | 'STOPPED'
  | 'UNKNOWN';

export type VehicleInfo = {
  __typename?: 'VehicleInfo';
  batteryCapacity: Scalars['Float']['output'];
  brand: JedlixBrand;
  model: Scalars['String']['output'];
  range: Scalars['Float']['output'];
  version: Scalars['String']['output'];
};

export type VehicleSmartChargingStatusInput = {
  vehicleId: Scalars['String']['input'];
};

export type VehicleSmartChargingStatusPayload = ConsideringSmartChargingStatus | DisabledSmartChargingStatus | FullyChargedSmartChargingStatus | PlannedSmartChargingStatus;

export type Vendor =
  | 'ADAX'
  | 'MILL';

/**
 * Capacity model used by Fjellnett/Eidefoss
 *
 * Computation steps, according to <https://www.fjellnett.no/privatkunder/category1153.html>:
 *
 * - Fetch consumption data for each hour for the previous twelve months.
 * - Multiply the consumption for each hour with the corresponding month weights.
 * - Find the peak value of every week.
 * - Pick the highest (5) `weekPeaksPerYear` and compute the average for these (with two decimals).
 * - Multiply by `pricePerAverageKwh`.
 * - Add `baseFeePerYear`.
 * - Divide by 12.
 */
export type WeightedYearlyRollingPeaksInDifferentWeeks = {
  __typename?: 'WeightedYearlyRollingPeaksInDifferentWeeks';
  baseFeePerYear: PriceSpecification;
  monthWeights: MonthWeights;
  pricePerKwhForAverageOfPeaks: PriceSpecification;
  type: CapacityModelType;
  weekPeaksPerYear: Scalars['Int']['output'];
};

export type ZaptecClaimInstallationInput = {
  installationId: Scalars['String']['input'];
};

export type ZaptecClaimInstallationPayload = {
  __typename?: 'ZaptecClaimInstallationPayload';
  success: Scalars['Boolean']['output'];
};

export type AddLocationMutationVariables = Exact<{
  input: AddLocationInput;
}>;


export type AddLocationMutation = { __typename?: 'Mutation', addLocation: { __typename?: 'AddLocationPayload', location: { __typename?: 'Location', id: string } } };

export type LocationsQueryVariables = Exact<{ [key: string]: never; }>;


export type LocationsQuery = { __typename?: 'Query', locations: Array<{ __typename?: 'Location', id: string, name: string, controllableIds: Array<string | null>, dso?: DeliveryChargeModelId | null, deviceId?: string | null, hourlyEnergyConsumptionLimit?: number | null, coordinates: { __typename?: 'LatLng', latitude: number, longitude: number } }> };

export type MyDevicesQueryVariables = Exact<{ [key: string]: never; }>;


export type MyDevicesQuery = { __typename?: 'Query', myDevices: Array<{ __typename?: 'DeviceInfo', deviceId: string, deviceName?: string | null }> };

export type SetDeviceForLocationMutationVariables = Exact<{
  input: SetDeviceForLocationInput;
}>;


export type SetDeviceForLocationMutation = { __typename?: 'Mutation', setDeviceForLocation: { __typename?: 'SetDeviceForLocationPayload', location: { __typename?: 'Location', id: string } } };

export type SetHourlyConsumptionLimitForLocationMutationVariables = Exact<{
  input: SetEnergyConsumptionLimitForLocationInput;
}>;


export type SetHourlyConsumptionLimitForLocationMutation = { __typename?: 'Mutation', setEnergyConsumptionLimitForLocation: { __typename?: 'SetEnergyConsumptionLimitForLocationPayload', location: { __typename?: 'Location', id: string } } };

export type SetCoordinatesForLocationMutationVariables = Exact<{
  input: SetCoordinatesForLocationInput;
}>;


export type SetCoordinatesForLocationMutation = { __typename?: 'Mutation', setCoordinatesForLocation: { __typename?: 'SetCoordinatesForLocationPayload', location: { __typename?: 'Location', id: string } } };

export type SetNameForLocationMutationVariables = Exact<{
  input: SetNameForLocationInput;
}>;


export type SetNameForLocationMutation = { __typename?: 'Mutation', setNameForLocation: { __typename?: 'SetNameForLocationPayload', location: { __typename?: 'Location', id: string } } };

export type AddControllableForLocationMutationVariables = Exact<{
  input: AddControllableForLocationInput;
}>;


export type AddControllableForLocationMutation = { __typename?: 'Mutation', addControllableForLocation: { __typename?: 'AddControllableForLocationPayload', location: { __typename?: 'Location', id: string } } };

export type ControllablesForUserQueryVariables = Exact<{ [key: string]: never; }>;


export type ControllablesForUserQuery = { __typename?: 'Query', listControllablesForUser: { __typename?: 'ControllablesForUserPayload', controllables: Array<{ __typename?: 'Controllable', id: string, intermediaryId?: string | null, name: string, type: ControllableType, vendor: Vendor, ownerId: string }> } };

export type RemoveControllableFromLocationMutationVariables = Exact<{
  input: RemoveControllableFromLocationInput;
}>;


export type RemoveControllableFromLocationMutation = { __typename?: 'Mutation', removeControllableFromLocation: { __typename?: 'RemoveControllableFromLocationPayload', location: { __typename?: 'Location', id: string } } };

export type StartIntegrationSessionMutationVariables = Exact<{
  input: IntegrationSessionInput;
}>;


export type StartIntegrationSessionMutation = { __typename?: 'Mutation', startIntegrationSession: { __typename?: 'IntegrationSessionPayload', url: string } };


export const AddLocationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"AddLocation"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"AddLocationInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"addLocation"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"location"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]}}]} as unknown as DocumentNode<AddLocationMutation, AddLocationMutationVariables>;
export const LocationsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"Locations"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"locations"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"controllableIds"}},{"kind":"Field","name":{"kind":"Name","value":"dso"}},{"kind":"Field","name":{"kind":"Name","value":"coordinates"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"latitude"}},{"kind":"Field","name":{"kind":"Name","value":"longitude"}}]}},{"kind":"Field","name":{"kind":"Name","value":"deviceId"}},{"kind":"Field","name":{"kind":"Name","value":"hourlyEnergyConsumptionLimit"}}]}}]}}]} as unknown as DocumentNode<LocationsQuery, LocationsQueryVariables>;
export const MyDevicesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"MyDevices"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"myDevices"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"deviceId"}},{"kind":"Field","name":{"kind":"Name","value":"deviceName"}}]}}]}}]} as unknown as DocumentNode<MyDevicesQuery, MyDevicesQueryVariables>;
export const SetDeviceForLocationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"SetDeviceForLocation"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"SetDeviceForLocationInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"setDeviceForLocation"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"location"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]}}]} as unknown as DocumentNode<SetDeviceForLocationMutation, SetDeviceForLocationMutationVariables>;
export const SetHourlyConsumptionLimitForLocationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"SetHourlyConsumptionLimitForLocation"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"SetEnergyConsumptionLimitForLocationInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"setEnergyConsumptionLimitForLocation"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"location"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]}}]} as unknown as DocumentNode<SetHourlyConsumptionLimitForLocationMutation, SetHourlyConsumptionLimitForLocationMutationVariables>;
export const SetCoordinatesForLocationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"SetCoordinatesForLocation"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"SetCoordinatesForLocationInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"setCoordinatesForLocation"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"location"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]}}]} as unknown as DocumentNode<SetCoordinatesForLocationMutation, SetCoordinatesForLocationMutationVariables>;
export const SetNameForLocationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"SetNameForLocation"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"SetNameForLocationInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"setNameForLocation"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"location"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]}}]} as unknown as DocumentNode<SetNameForLocationMutation, SetNameForLocationMutationVariables>;
export const AddControllableForLocationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"AddControllableForLocation"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"AddControllableForLocationInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"addControllableForLocation"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"location"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]}}]} as unknown as DocumentNode<AddControllableForLocationMutation, AddControllableForLocationMutationVariables>;
export const ControllablesForUserDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"ControllablesForUser"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"listControllablesForUser"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"controllables"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"intermediaryId"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"type"}},{"kind":"Field","name":{"kind":"Name","value":"vendor"}},{"kind":"Field","name":{"kind":"Name","value":"ownerId"}}]}}]}}]}}]} as unknown as DocumentNode<ControllablesForUserQuery, ControllablesForUserQueryVariables>;
export const RemoveControllableFromLocationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"RemoveControllableFromLocation"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"RemoveControllableFromLocationInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"removeControllableFromLocation"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"location"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]}}]} as unknown as DocumentNode<RemoveControllableFromLocationMutation, RemoveControllableFromLocationMutationVariables>;
export const StartIntegrationSessionDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"StartIntegrationSession"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"IntegrationSessionInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"startIntegrationSession"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"url"}}]}}]}}]} as unknown as DocumentNode<StartIntegrationSessionMutation, StartIntegrationSessionMutationVariables>;