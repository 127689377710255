import { useMutation } from "@apollo/client";
import { InputType, Spacing } from "@hark-tech/components";
import { useState } from "react";
import { Button, ButtonVariant, Input } from "../../components";
import { SetHourlyConsumptionLimitForLocationDocument } from "../../generated/graphql/graphql";
import { BaseHeader, ScreenWrapper } from "../smart-control";

interface AddHourlyConsumptionLimitProps {
  hourlyConsumptionLimit?: number;
  locationId: string;
  onFinish: () => void;
  onBackClick?: () => void;
}

export const AddHourlyConsumptionLimit = ({
  hourlyConsumptionLimit,
  locationId,
  onFinish,
  onBackClick,
}: AddHourlyConsumptionLimitProps): JSX.Element => {
  const [limit, setLimit] = useState<number>(hourlyConsumptionLimit ?? 0);

  const [addHourlyConsumptionLimit, { loading, error }] = useMutation(
    SetHourlyConsumptionLimitForLocationDocument,
    {
      onCompleted: () => {
        onFinish();
      },
      variables: {
        input: {
          limit,
          locationId,
        },
      },
    }
  );

  return (
    <ScreenWrapper
      header={
        <BaseHeader
          title={
            hourlyConsumptionLimit ? "Update location" : "Add new location"
          }
          rightButton="3/3"
        />
      }
      childSpacing={Spacing.MEDIUM}
    >
      <Input
        id="name"
        type={InputType.NUMBER}
        value={limit.toString()}
        onChange={(value) => setLimit(parseFloat(value))}
        label="Grid charge limit (Wh)"
      />

      <Button
        buttonVariant={ButtonVariant.GREEN}
        onClick={addHourlyConsumptionLimit}
        text={hourlyConsumptionLimit ? "Save changes" : "Complete setup"}
        disabled={limit === 0}
        loading={loading}
        error={error?.message}
      />

      <Button
        buttonVariant={ButtonVariant.WHITE}
        disabled={!onBackClick}
        onClick={() => (onBackClick ? onBackClick() : undefined)}
        text="Cancel"
      />
    </ScreenWrapper>
  );
};
